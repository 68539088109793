import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
export const Option = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col>
                    <Form.Group >
                        <Form.Control placeholder="Which Drop Down List?" autocomplete="off" onChange={props.questionObj.handleAnswer2TextChange(
                            props.questionObj.questionPlacement, 0)} hidden={props.questionObj.question.autoLabel ? true : false} value={props.questionObj.answer1} type="text" required />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Control placeholder="Which Option to Select?" autocomplete="off" onChange={props.questionObj.handleAnswer2TextChange(
                            props.questionObj.questionPlacement, 1)} value={props.questionObj.answer2} type="text" required />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="btn btn-primary" disabled={props.questionObj.isAnswerButtonDisabled} onClick={props.questionObj.navigateQuestionnaire(props.questionObj.steps)} block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div>
    )
}));