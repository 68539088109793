import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
export const LoginConsent = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col>
                    <Button variant="success" onClick={props.questionObj.acceptQuestionnaireFromLoginConsent(props.questionObj.steps,props.questionObj.questionPlacement)} block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div>
    )
}));