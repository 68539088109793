import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import TimePicker from 'rc-time-picker';
import moment from 'moment'
const format = 'hh:mm A';

const now = moment().hour(6).minute(0);
export const PickTime = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col style={{ textAlign: 'center' }}>
                    <Form.Group>
                        <TimePicker
                            showSecond={false}
                            onChange={props.questionObj.handlePickTimeOnChange(props.questionObj.questionPlacement)}
                            format={format}
                            use12Hours
                            minuteStep={15}
                            inputReadOnly
                            value={props.questionObj.answer1 &&  moment(props.questionObj.answer1, 'hh:mm A').isValid() ? moment(props.questionObj.answer1, 'hh:mm A') : now}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="btn btn-primary" disabled={props.questionObj.isAnswerButtonDisabled} onClick={props.questionObj.navigateQuestionnaire(props.questionObj.steps)} block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div>
    )
}));