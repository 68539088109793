import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import NumberFormat from 'react-number-format';
export const SMS = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col>
                    <Form.Group >
                        <Form.Label>Text Bot Results To (US or Canada # only):</Form.Label>
                        <NumberFormat
                                        autoComplete="off" onChange={props.questionObj.handleAnswer1TextChange(props.questionObj.questionPlacement, props.questionObj.question.positiveQuestionType)}
                                        value={props.questionObj.answer1} type="tel"
                                        format="###-###-####"
                                        className={"form-control"}
                                        placeholder="000-000-0000"
                                        required />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="btn btn-primary" disabled={props.questionObj.isAnswerButtonDisabled} onClick={props.questionObj.navigateQuestionnaire(props.questionObj.steps)} block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div>
    )
}));