import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
export const ListSelector = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col>
                    <Form.Group >
                        <Form.Control placeholder={(props.questionObj.question.isBeforeSpreadSheetForm || props.questionObj.question.isAfterSpreadSheetForm) ? 
                        "Search Value" : "Which List to Select From?"} autocomplete="off" onChange={props.questionObj.handleAnswer3TextChange(
                            props.questionObj.questionPlacement, 0, false)}
                            value={props.questionObj.answer1}
                            type="text" hidden={props.questionObj.question.autoLabel ? true : false} required />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Text className="text-muted">Items to select</Form.Text>
                        <Form.Control placeholder="Required" autocomplete="off" onChange={props.questionObj.handleAnswer3TextChange(
                            props.questionObj.questionPlacement, 1, props.questionObj.question.positiveQuestionType, false)} value={props.questionObj.answer2} type="text" required />
                        <Form.Group controlId="matchRequiredSpecifics" hidden={true}>
                            <Form.Check type="checkbox" checked={props.questionObj.yesNo1}
                                onChange={props.questionObj.handleYesNo1Change} label="Match word for word" />
                        </Form.Group>
                    </Form.Group>
                </Col>
            </Row>
            <Row hidden={true}>
                <Col>
                    <Form.Group>
                        <Form.Text className="text-muted">Additional items to select (Optional)</Form.Text>
                        <Form.Control placeholder="Optional" autocomplete="off" onChange={props.questionObj.handleAnswer3TextChange(
                            props.questionObj.questionPlacement, 2, props.questionObj.question.positiveQuestionType, false)} value={props.questionObj.answer3} type="text" required />
                        <Form.Group controlId="matchOptionalSpecifics">
                            <Form.Check type="checkbox" checked={props.questionObj.yesNo2}
                                onChange={props.questionObj.handleYesNo2Change} label="Match word for word" />
                        </Form.Group>

                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="btn btn-primary" disabled={props.questionObj.isAnswerButtonDisabled} onClick={props.questionObj.navigateQuestionnaire(props.questionObj.steps)} block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div>
    )
}));