import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

export const MiniBotAdvanceDayPicker = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col xs={2}>
                    <Form.Group controlId="chkSun">
                        <Form.Check type="checkbox" disabled={props.questionObj.existingDay && props.questionObj.existingDay.includes("SUNDAY")} checked=
                            {props.questionObj.answer1 &&
                                props.questionObj.answer1.includes('SUNDAY')} label="Sun" onChange={props.questionObj.handleDayPickerOnChange(props.questionObj.questionPlacement)} />
                    </Form.Group>
                </Col>
                <Col xs={2}>
                    <Form.Group controlId="chkMon">
                        <Form.Check disabled={props.questionObj.existingDay && props.questionObj.existingDay.includes("MONDAY")} checked=
                            {props.questionObj.answer1 &&
                                props.questionObj.answer1.includes('MONDAY')} type="checkbox" label="Mon" onChange={props.questionObj.handleDayPickerOnChange(props.questionObj.questionPlacement)} />
                    </Form.Group>
                </Col>
                <Col xs={2}>
                    <Form.Group controlId="chkTues">
                        <Form.Check disabled={props.questionObj.existingDay && props.questionObj.existingDay.includes("TUESDAY")} type="checkbox" checked=
                            {props.questionObj.answer1 &&
                                props.questionObj.answer1.includes('TUESDAY')} label="Tues" onChange={props.questionObj.handleDayPickerOnChange(props.questionObj.questionPlacement)} />
                    </Form.Group>
                </Col>
                <Col xs={2}>
                    <Form.Group controlId="chkWed">
                        <Form.Check disabled={props.questionObj.existingDay && props.questionObj.existingDay.includes("WEDNESDAY")} type="checkbox" checked=
                            {props.questionObj.answer1 &&
                                props.questionObj.answer1.includes('WEDNESDAY')} label="Wed" onChange={props.questionObj.handleDayPickerOnChange(props.questionObj.questionPlacement)} />
                    </Form.Group>
                </Col>
                <Col xs={2}>
                    <Form.Group controlId="chkThurs">
                        <Form.Check disabled={props.questionObj.existingDay && props.questionObj.existingDay.includes("THURSDAY")} type="checkbox" checked=
                            {props.questionObj.answer1 &&
                                props.questionObj.answer1.includes('THURSDAY')} label="Thurs" onChange={props.questionObj.handleDayPickerOnChange(props.questionObj.questionPlacement)} />
                    </Form.Group>
                </Col>
                <Col xs={2}>
                    <Form.Group controlId="chkFri">
                        <Form.Check disabled={props.questionObj.existingDay && props.questionObj.existingDay.includes("FRIDAY")} type="checkbox" checked=
                            {props.questionObj.answer1 &&
                                props.questionObj.answer1.includes('FRIDAY')} label="Fri" onChange={props.questionObj.handleDayPickerOnChange(props.questionObj.questionPlacement)} />
                    </Form.Group>
                </Col>
                <Col xs={2}>
                    <Form.Group controlId="chkSat">
                        <Form.Check disabled={props.questionObj.existingDay && props.questionObj.existingDay.includes("SATURDAY")} type="checkbox" checked=
                            {props.questionObj.answer1 &&
                                props.questionObj.answer1.includes('SATURDAY')} label="Sat" onChange={props.questionObj.handleDayPickerOnChange(props.questionObj.questionPlacement)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="btn btn-primary" disabled={props.questionObj.isAnswerButtonDisabled} onClick={props.questionObj.navigateQuestionnaire(props.questionObj.steps)} block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div>
    )
}));