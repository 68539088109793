import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
export const ExternalSite = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col>
                    <Button className="btn btn-primary" href="https://www.zoho.com/sheet" target="_blank" block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div>
    )
}));