import React, { Component } from 'react';
import { Formik } from "formik";
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import bwLogo from '../../assets/Logo_BnW@3x.png';
import ApiClient from '../../util/ApiClient';
@inject('rootStore')
@observer
export default class Support extends Component {
    constructor() {
        super();
        this.state = {
            subscription: '',
            name: '',
            email: ''
        };
    }
    componentDidMount() {
        ApiClient.get('user/profile').then((response) => {
            if (response) {
                this.setState({ subscription: response.data.subscriptionName, name: response.data.name, email: response.data.emailAddress })
            }
        }).catch((error) => {
        });
    }
    render() {

        const rootStore = this.props.rootStore;

        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={12}>
                        <Jumbotron style={{ backgroundColor: '#ece6e6' }}>
                            <Row>
                                <Col style={{ textAlign: "center", marginBottom: "20px" }}>
                                    <img src={bwLogo} width="180px" height="90px" style={{ paddingRight: '10px' }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "center" }}>
                                    <h3>Contact Bot-It Support</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Formik


                                        initialValues={{
                                            name: this.state.name,
                                            subscription: this.state.subscription,
                                            email: this.state.email,
                                            subject: "",
                                            message: ""
                                        }}
                                        enableReinitialize={true}
                                        onSubmit={(values, actions) => {
                                            ApiClient.post('user/contact/support', {
                                                name: values.name,
                                                emailAddress: values.email,
                                                subscriptionName: values.subscription,
                                                subject: values.subject,
                                                message: values.message
                                            }).then((response) => {
                                                if (response.status == 200) {
                                                    actions.resetForm({
                                                        name: values.name,
                                                        emailAddress: values.email,
                                                        subscriptionName: values.subscription,
                                                        subject: '',
                                                        message: ''
                                                    })
                                                    actions.setStatus('Thank you for contacting our support team! We have received your message and will respond within 24 hours.');
                                                    actions.setSubmitting(false);
                                                }
                                                else {
                                                    actions.setSubmitting(false);
                                                    actions.setValues(values, false);
                                                    this.setState({ apiError: "Login Error. Please try again" })
                                                }

                                            }).catch((error) => {
                                                actions.setSubmitting(false);
                                                actions.setValues(values, false)
                                                this.setState({ apiError: error.response.data.error })

                                            })


                                        }}
                                    >
                                        {formik => (
                                            <Form onSubmit={formik.handleSubmit}>
                                                <Form.Group controlId="name">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} disabled={true} placeholder="Name" required />
                                                </Form.Group>
                                                <Form.Group controlId="email">
                                                    <Form.Label>Email Address</Form.Label>
                                                    <Form.Control type="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} disabled={true} placeholder="Email Address" required />
                                                </Form.Group>
                                                <Form.Group controlId="subscription">
                                                    <Form.Label>Subscription</Form.Label>
                                                    <Form.Control type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.subscription} disabled={true} placeholder="Subscription" required />
                                                </Form.Group>
                                                <Form.Group controlId="subject">
                                                    <Form.Label>Subject</Form.Label>
                                                    <Form.Control type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.subject} placeholder="Subject" required />
                                                </Form.Group>
                                                <Form.Group controlId="message">
                                                    <Form.Label>Message</Form.Label>
                                                    <Form.Control onChange={formik.handleChange} as="textarea" onBlur={formik.handleBlur} value={formik.values.message} placeholder="Message" rows="3" minLength={6} required />
                                                </Form.Group>

                                                <Row style={{ textAlign: 'center' }}>
                                                    <Col lg={12}>
                                                        <Button variant="primary" type="submit" disabled={formik.isSubmitting}> {formik.isSubmitting ? "Please wait..." : "Contact Us"}</Button>
                                                    </Col>
                                                </Row>
                                                <Row style={{ textAlign: 'center' }}>
                                                    <Col lg={12}>
                                                        <span style={{ color: 'green' }}>{formik.status}</span>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </Col>
                            </Row>

                        </Jumbotron>

                    </Col>
                </Row>

            </Container >
        )
    }
}