import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import { getParameterByName } from '../../util/queryString';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ApiClient from '../../util/ApiClient';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal'
function LoadingModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={7000}
                        />
                    </Col>
                </Row>

            </Modal.Body>
        </Modal >
    );
}
@inject('rootStore')
@observer
export default class Referral extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true
        };
    }
    componentDidMount() {

        var referralCode = getParameterByName('r', window.location.href);
        if (referralCode) {
            const token = localStorage.getItem('token');
            if (token) {
                window.location = '/'
            }
            else {
                window.location = '/login?r=' + referralCode
            }

        } else {
            window.location = '/'
        }

    }
    render() {

        const rootStore = this.props.rootStore;

        return (
            <Container>
                <LoadingModal
                    show={this.state.isLoading}
                    backdrop={'static'}
                    keyboard={false}
                />
            </Container>
        )
    }
}