import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import infoLight from '../../assets/Info-Light-icon.png';
import { NameBot } from './QuestionTypes/NameBot';
import { BotUrl } from './QuestionTypes/BotUrl';
import { LoginInfo } from './QuestionTypes/LoginInfo';
import { Email } from './QuestionTypes/Email';
import { SMS } from './QuestionTypes/SMS';
import { LoginConsent } from './QuestionTypes/LoginConsent';
import { Option } from './QuestionTypes/Option';
import { ButtonInfo } from './QuestionTypes/Button';
import { Typing } from './QuestionTypes/Typing';
import { PickTime } from './QuestionTypes/PickTime';
import { PickDate } from './QuestionTypes/PickDate';
import { ExternalSite } from './QuestionTypes/ExternalSite';
import { SpreadsheetUrl } from './QuestionTypes/SpreadsheetUrl';
import { QuestionnaireTimePicker } from './QuestionTypes/TimePicker';
import { DayPicker } from './QuestionTypes/DayPicker';
import { ListSelector } from './QuestionTypes/ListSelector';
import { TimezonePicker } from './QuestionTypes/TimeZonePicker';
import { SpreadsheetColumn } from './QuestionTypes/SpreadsheetColumn';
import { StepPicker } from './QuestionTypes/StepPicker';
import { MiniBotSearch } from './QuestionTypes/MiniBotSearch';
import { Feedback } from './QuestionTypes/Feedback';
import { GoHome } from './QuestionTypes/GoHome';
import { GoSubscription } from './QuestionTypes/GoSubscription';
import { MiniBotAdvanceDayPicker } from './QuestionTypes/MiniBotAdvanceDayPicker';
import { MiniBotTimeRangePicker } from './QuestionTypes/MiniBotTimeRangePicker';
import '../../css/series.css';
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import ListGroup from 'react-bootstrap/ListGroup'
import deleteIcon from '../../assets/Delete-icon.png';

function ShowToolTipMessage(message) {
    if (message)
        return (
            <OverlayTrigger trigger="click" rootClose placement="left" overlay={<Popover id="popover-basic">
                <Popover.Title as="h3">Bot-It Tip!</Popover.Title>
                <Popover.Content>
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                </Popover.Content>
            </Popover>}>
                <img src={infoLight} width={24} height={24} style={{ marginLeft: "10px" }} />
            </OverlayTrigger>
        )
}
function GetControlObject(props, buttonText, stepCounter) {
    return {
        question: props.series[props.currentQuestionIterator].question,
        questionPlacement: props.currentQuestionIterator,
        buttonText: buttonText,
        steps: stepCounter,
        currentSeriesId: props.currentSeriesId,
        answer1: GetAnswer1(props),
        handleAnswer1TextChange: props.handleAnswer1TextChange,

        answer2: GetAnswer2(props),
        handleAnswer2TextChange: props.handleAnswer2TextChange,

        answer3: GetAnswer3(props),
        handleAnswer3TextChange: props.handleAnswer3TextChange,

        datePicker: GetDatePickerAnswer(props),
        datePickerValueChange: props.datePickerValueChange,
        handleOnDatePickerRepeatOnChange: props.handleOnDatePickerRepeatOnChange,
        handlePickTimeOnChange: props.handlePickTimeOnChange,

        yesNo1: GetYesNo1(props),
        handleYesNo1Change: props.handleYesNo1Change,

        yesNo2: GetYesNo2(props),
        handleYesNo2Change: props.handleYesNo2Change,

        onMiniBotMenuToggle: props.onMiniBotMenuToggle,

        dayPicker: props.series[props.currentQuestionIterator].answer ? props.series[props.currentQuestionIterator].answer.botRunDays : null,
        handleDayPickerOnChange: props.handleDayPickerOnChange,
        addMiniBotTag: props.addMiniBotTag,
        removeMiniBotTag: props.removeMiniBotTag,
        miniBotTags: props.miniBotTags,

        time: props.series[props.currentQuestionIterator].answer ? props.series[props.currentQuestionIterator].answer.time : null,
        handleTimePickerOnValueChange: props.handleTimePickerOnValueChange,
        onSeriesWindowStartHour: props.onSeriesWindowStartHour,
        onSeriesWindowEndHour: props.onSeriesWindowEndHour,

        existingDay: props.existingDay,

        navigateQuestionnaire: props.navigateQuestionnaire,
        exampleBotName: props.exampleBotName,
        isAnswerButtonDisabled: props.isAnswerButtonDisabled,
        errorMessageText: props.errorMessageText,
        hideErrorMessage: props.hideErrorMessage,
        showQuestionnairePassword: props.showQuestionnairePassword,
        handleShowQuestionnairePasswordChange: props.handleShowQuestionnairePasswordChange,
        handleOnTimeZoneChange: props.handleOnTimeZoneChange,
        handleOnFieldTypeChange: props.handleOnFieldTypeChange,
        supportedTimeZones: props.supportedTimeZones,
        supportedGolfCourses: props.supportedGolfCourses,
        supportedTennisCourts: props.supportedTennisCourts,
        supportedPickleBallCourts: props.supportedPickleBallCourts,
        supportedCampingSites: props.supportedCampingSites,
        supportedDinnerSites: props.supportedDinnerSites,
        miniBotSearchType: props.miniBotSearchType,
        repeatersAllowed: props.repeatersAllowed,
        acceptQuestionnaireFromLoginConsent: props.acceptQuestionnaireFromLoginConsent,
        handleStepPickerSelection: props.handleStepPickerSelection,
        hideFrequencyErrorMessage: props.hideFrequencyErrorMessage
    };
}
function GetAnswer1(props) {
    if (props.inPositiveRepeatMode) {
        return props.series[props.currentQuestionIterator].answer && props.positiveRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.positiveRepeatCount].answer1 : null
    }
    else if (props.inNegativeRepeatMode) {
        return props.series[props.currentQuestionIterator].answer && props.negativeRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.negativeRepeatCount].answer1 : null
    }
    else if (props.inStepPickerMode) {
        return props.series[props.currentQuestionIterator].answer && props.stepPickerRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.stepPickerRepeatCount].answer1 : null
    }
    else {
        return props.series[props.currentQuestionIterator].answer ? props.series[props.currentQuestionIterator].answer.answer1 : null
    }
}
function GetAnswer2(props) {
    if (props.inPositiveRepeatMode) {
        return props.series[props.currentQuestionIterator].answer && props.positiveRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.positiveRepeatCount].answer2 : null
    }
    else if (props.inNegativeRepeatMode) {
        return props.series[props.currentQuestionIterator].answer && props.negativeRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.negativeRepeatCount].answer2 : null
    }
    else if (props.inStepPickerMode) {
        return props.series[props.currentQuestionIterator].answer && props.stepPickerRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.stepPickerRepeatCount].answer2 : null
    }
    else {
        return props.series[props.currentQuestionIterator].answer ? props.series[props.currentQuestionIterator].answer.answer2 : null
    }
}
function GetAnswer3(props) {
    if (props.inPositiveRepeatMode) {
        return props.series[props.currentQuestionIterator].answer && props.positiveRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.positiveRepeatCount].answer3 : null
    }
    else if (props.inNegativeRepeatMode) {
        return props.series[props.currentQuestionIterator].answer && props.negativeRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.negativeRepeatCount].answer3 : null
    }
    else if (props.inStepPickerMode) {
        return props.series[props.currentQuestionIterator].answer && props.stepPickerRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.stepPickerRepeatCount].answer3 : null
    }
    else {
        return props.series[props.currentQuestionIterator].answer ? props.series[props.currentQuestionIterator].answer.answer3 : null
    }
}
function GetYesNo1(props) {
    if (props.inPositiveRepeatMode) {
        return props.series[props.currentQuestionIterator].answer && props.positiveRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.positiveRepeatCount].yesNo1 : null
    }
    else if (props.inNegativeRepeatMode) {
        return props.series[props.currentQuestionIterator].answer && props.negativeRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.negativeRepeatCount].yesNo1 : null
    }
    else if (props.inStepPickerMode) {
        return props.series[props.currentQuestionIterator].answer && props.stepPickerRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.stepPickerRepeatCount].yesNo1 : null
    }
    else {
        return props.series[props.currentQuestionIterator].answer ? props.series[props.currentQuestionIterator].answer.yesNo1 : null
    }
}
function GetYesNo2(props) {
    if (props.inPositiveRepeatMode) {
        return props.series[props.currentQuestionIterator].answer && props.positiveRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.positiveRepeatCount].yesNo2 : null
    }
    else if (props.inNegativeRepeatMode) {
        return props.series[props.currentQuestionIterator].answer && props.negativeRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.negativeRepeatCount].yesNo2 : null
    }
    else if (props.inStepPickerMode) {
        return props.series[props.currentQuestionIterator].answer && props.stepPickerRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.stepPickerRepeatCount].yesNo2 : null
    }
    else {
        return props.series[props.currentQuestionIterator].answer ? props.series[props.currentQuestionIterator].answer.yesNo2 : null
    }
}
function GetDatePickerAnswer(props) {
    if (props.inPositiveRepeatMode) {
        return props.series[props.currentQuestionIterator].answer && props.positiveRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.positiveRepeatCount].datePicker : null
    }
    else if (props.inNegativeRepeatMode) {
        return props.series[props.currentQuestionIterator].answer && props.negativeRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.negativeRepeatCount].datePicker : null
    }
    else if (props.inStepPickerMode) {
        return props.series[props.currentQuestionIterator].answer && props.stepPickerRepeatCount < props.series[props.currentQuestionIterator].answer.length ? props.series[props.currentQuestionIterator].answer[props.stepPickerRepeatCount].datePicker : null
    }
    else {
        return props.series[props.currentQuestionIterator].answer ? props.series[props.currentQuestionIterator].answer.datePicker : null
    }
}
function GetQuestionControls(questionObj) {
    switch (questionObj.question.positiveQuestionType) {
        case "NAMEBOT":
            return (<NameBot questionObj={questionObj} />)
        case "URL":
            return (<BotUrl questionObj={questionObj} />)
        case "LOGIN":
            return (<LoginInfo questionObj={questionObj} />)
        case "EMAILRESULTS":
            return (<Email questionObj={questionObj} />)
        case "TEXTRESULTS":
            return (<SMS questionObj={questionObj} />)
        case "LOGINCONSENTAGREED":
            return (<LoginConsent questionObj={questionObj} />)
        case "OPTION":
            return (<Option questionObj={questionObj} />)
        case "BUTTONCLICK":
            return (<ButtonInfo questionObj={questionObj} />)
        case "TYPING":
            return (<Typing questionObj={questionObj} />)
        case "PICKDATE":
            return (<PickDate questionObj={questionObj} />)
        case "PICKTIME":
            return (<PickTime questionObj={questionObj} />)
        case "EXTERNALSITE_ZOHO":
            return (<ExternalSite questionObj={questionObj} />)
        case "SPREADSHEETURL":
            return (<SpreadsheetUrl questionObj={questionObj} />)
        case "TIMEPICKER":
            return (<QuestionnaireTimePicker questionObj={questionObj} />)
        case "DAYPICKER":
            return (<DayPicker questionObj={questionObj} />)
        case "LISTSELECTOR":
            return (<ListSelector questionObj={questionObj} />)
        case "TIMEZONEPICKER":
            return (<TimezonePicker questionObj={questionObj} />)
        case "ADDSPREADSHEETCOLUMN":
            return (<SpreadsheetColumn questionObj={questionObj} />)
        case "STEPPICKER":
            return (<StepPicker questionObj={questionObj} />)
        case "SHARETEETIMEBOTLOOKUP":
            return (<MiniBotSearch questionObj={questionObj} />)
        case "FEEDBACKMESSAGE":
            return (<Feedback questionObj={questionObj} />)
        case "GOHOME":
            return (<GoHome questionObj={questionObj} />)
        case "GOSUBSCRIPTION":
            return (<GoSubscription questionObj={questionObj} />)
        case "MINIBOTADVANCEDAYPICKER":
            return (<MiniBotAdvanceDayPicker questionObj={questionObj} />)
        case "MINIBOTTIMERANGEPICKER":
            return (<MiniBotTimeRangePicker questionObj={questionObj} />)
    }
}
function GetIsPositiveDisabled(props) {
    return (props.inPositiveRepeatMode || props.inNegativeRepeatMode) &&
        props.positiveRepeatCount >= props.series[props.currentQuestionIterator +
            props.series[props.currentQuestionIterator].question.positiveStepCounter].answer.length;
}
function GetPositiveOnClick(props) {
    return props.navigateQuestionnaire(
        props.series[props.currentQuestionIterator].question.positiveStepCounter,
        props.series[props.currentQuestionIterator].question.positiveQuestionType,
        props.series[props.currentQuestionIterator].question.isRepeaterSetup, true, false);
}
function GetPositiveButtonText(props) {
    return !props.series[props.currentQuestionIterator].question.isRepeaterSetup ? props.series[props.currentQuestionIterator].question.positiveText :
        props.series[props.currentQuestionIterator].question.positiveText /*+ " (limit: " + (props.series[props.currentQuestionIterator + props.series[props.currentQuestionIterator].question.positiveStepCounter].answer.length - props.positiveRepeatCount) + ")"*/;
}
function GetIsNegativeDisabled(props) {
    return props.series[props.currentQuestionIterator].question.isRepeaterSetup &&
        props.series[props.currentQuestionIterator + props.series[props.currentQuestionIterator].question.negativeStepCounter].answer &&
        props.series[props.currentQuestionIterator + props.series[props.currentQuestionIterator].question.negativeStepCounter].answer.length > 0 &&
        (props.inPositiveRepeatMode || props.inNegativeRepeatMode) &&
        props.negativeRepeatCount >= props.series[props.currentQuestionIterator + props.series[props.currentQuestionIterator].question.negativeStepCounter].answer.length;
}
function GetNegativeButtonVariant(props) {
    return props.series[props.currentQuestionIterator].question.showPositiveButton &&
        props.series[props.currentQuestionIterator].question.positiveQuestionType != "LOGINCONSENTAGREED" ? "success" : "danger";
}
function GetNegativeOnClick(props) {
    return props.series[props.currentQuestionIterator].question.positiveQuestionType != "LOGINCONSENTAGREED" ?
        props.navigateQuestionnaire(props.series[props.currentQuestionIterator].question.negativeStepCounter,
            props.series[props.currentQuestionIterator].question.positiveQuestionType,
            props.series[props.currentQuestionIterator].question.isRepeaterSetup, false, true, props.series[props.currentQuestionIterator].question.showNeutralButton,
            null, null, props.series[props.currentQuestionIterator].question.negativeHref) :
        props.discardQuestionnaireFromLoginConsent
}
function GetNegativeText(props) {
    return props.series[props.currentQuestionIterator].question.isRepeaterSetup &&
        props.series[props.currentQuestionIterator + props.series[props.currentQuestionIterator].question.negativeStepCounter].answer &&
        props.series[props.currentQuestionIterator + props.series[props.currentQuestionIterator].question.negativeStepCounter].answer.length > 0 ?
        props.series[props.currentQuestionIterator].question.negativeText /*+ " (limit: " + (props.series[props.currentQuestionIterator + props.series[props.currentQuestionIterator].question.negativeStepCounter].answer.length - props.negativeRepeatCount) + ")"*/
        : props.series[props.currentQuestionIterator].question.negativeText;

}

function Questionnaire(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header>
                <Modal.Title>
                    <h3>{props.seriesTitle}<a style={{ display: 'inline', marginLeft: '10px', fontSize: '12px' }}
                        href={props.currentSeriesId == "605c38336f04a82ed6d6b9bd" ? "http://botit.com/setup.html?isimport=true" : "https://www.youtube.com/watch?v=gaVrjdbeU_8"}
                        target="_blank" class="badge badge-info">Need help?</a></h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col><h4 className="question_title">{(props.positiveRepeatCount > 0 || props.negativeRepeatCount > 0 || props.stepPickerRepeatCount > 0) && props.series[props.currentQuestionIterator].question.rephrasedQuestion ?
                        props.series[props.currentQuestionIterator].question.rephrasedQuestion :
                        props.premiumBotSelectedName && props.series[props.currentQuestionIterator].question.question.includes("%MINI_BOT_NAME%") ? props.series[props.currentQuestionIterator].question.question.replaceAll("%MINI_BOT_NAME%", props.premiumBotSelectedName) :
                            props.series[props.currentQuestionIterator].question.question}  {ShowToolTipMessage(props.series[props.currentQuestionIterator].question.toolTipMessage)}</h4>

                    </Col>
                </Row>
                {props.series[props.currentQuestionIterator].question.showPositiveButton && props.series[props.currentQuestionIterator].question.positiveQuestionType ? (
                    GetQuestionControls(GetControlObject(props, props.series[props.currentQuestionIterator].question.positiveText, props.series[props.currentQuestionIterator].question.positiveStepCounter))) : (
                    <Row>
                        <Col>
                            {props.series[props.currentQuestionIterator].question.showPositiveButton &&
                                <Button disabled={GetIsPositiveDisabled(props)}
                                    className="buttonGroup" variant="primary" block onClick={GetPositiveOnClick(props)}>
                                    {GetPositiveButtonText(props)}</Button>
                            }
                        </Col>
                    </Row>
                )}
                {props.series[props.currentQuestionIterator].question.showNegativeButton &&
                    <Row>
                        <Col>
                            <Button disabled={GetIsNegativeDisabled(props)}
                                className="buttonGroup" block variant={GetNegativeButtonVariant(props)}
                                onClick={GetNegativeOnClick(props)}>{GetNegativeText(props)}</Button>
                        </Col>
                    </Row>
                }
                {props.series[props.currentQuestionIterator].question.showNeutralButton &&
                    <Row>
                        <Col>
                            <Button className="buttonGroup" block variant="danger" onClick={props.navigateQuestionnaire(props.series[props.currentQuestionIterator].question.neutralStepCounter, null, null, null, null, null, null, null, null, props.series[props.currentQuestionIterator].question.neutralHref)}>{props.series[props.currentQuestionIterator].question.neutralText}</Button>
                        </Col>
                    </Row>
                }
                {props.series[props.currentQuestionIterator].question.imageHelper &&
                    <Form.Group controlId="showExampleCheckbox">
                        <Form.Check type="checkbox" checked={props.showQuestionImageHelper} onChange={props.handleShowImageHelperChange} label="Need an example?" />
                    </Form.Group>
                }
                {props.series[props.currentQuestionIterator].question.imageHelper && props.showQuestionImageHelper &&
                    <Image src={props.series[props.currentQuestionIterator].question.imageHelper} fluid />
                }
                {props.series[props.currentQuestionIterator].question.positiveQuestionType
                    && props.series[props.currentQuestionIterator].question.positiveQuestionType == "STEPPICKER" && props.botStepList &&
                    <div>
                        <ListGroup>
                            {props.botStepList.map((item, i) => {
                                return <ListGroup.Item>{"Step " + (i + 1) + " - " + item.stepValue}<img onClick={props.onClickDeleteBotListStep(i, item.stepValue, item.questionPlacement)} src={deleteIcon} height="16" width="16" style={{ float: 'right' }} /></ListGroup.Item>
                            })}
                        </ListGroup>
                    </div>}
                {props.series[props.currentQuestionIterator].question.questionId == "605c261c411acf2d10d716e3" && props.columnFieldList &&
                    <div>
                        <ListGroup>
                            {props.columnFieldList.map((item, i) => {
                                return <ListGroup.Item>{(i + 1) + ". Column: " + item.columnValue}<img onClick={props.onClickDeleteColumnFieldListStep(i, item.questionPlacement)} src={deleteIcon} height="16" width="16" style={{ float: 'right' }} /></ListGroup.Item>
                            })}
                        </ListGroup>
                    </div>}

            </Modal.Body>
            <Modal.Footer>
                <Col lg="12">
                    <Button variant="secondary" hidden={props.hideBackButton} disabled={props.currentQuestionIterator == 0} onClick={props.handleNavigateBack}>Back</Button>
                </Col>
                <Row>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}
export const SeriesQuestions = inject("rootStore")(observer((props) => (
    <div>
        {props.showSeriesQuestionnaireModal &&
            <Questionnaire
                show={props.showSeriesQuestionnaireModal}
                onHide={() => props.handleHideSeriesQuestionnaire(false, true)}
                hideSeriesQuestionnaireHelper={() => props.handleHideSeriesQuestionnaire(false)}
                showQuestionImageHelper={props.showQuestionImageHelper}
                series={props.series}
                currentSeriesId={props.currentSeriesId}
                seriesTitle={props.seriesTitle}
                currentQuestionIterator={props.currentQuestionIterator}
                handleAnswer1TextChange={props.handleAnswer1TextChange}
                handleAnswer2TextChange={props.handleAnswer2TextChange}
                handleAnswer3TextChange={props.handleAnswer3TextChange}
                datePickerValueChange={props.datePickerValueChange}
                handleOnDatePickerRepeatOnChange={props.handleOnDatePickerRepeatOnChange}
                handlePickTimeOnChange={props.handlePickTimeOnChange}
                handleYesNo1Change={props.handleYesNo1Change}
                handleYesNo2Change={props.handleYesNo2Change}
                onMiniBotMenuToggle={props.onMiniBotMenuToggle}
                handleDayPickerOnChange={props.handleDayPickerOnChange}
                handleTimePickerOnValueChange={props.handleTimePickerOnValueChange}
                onSeriesWindowStartHour={props.onSeriesWindowStartHour}
                onSeriesWindowEndHour={props.onSeriesWindowEndHour}
                navigateQuestionnaire={props.navigateQuestionnaire}
                backdrop={'static'}
                keyboard={false}
                exampleBotName={props.exampleBotName}
                isAnswerButtonDisabled={props.isAnswerButtonDisabled}
                hideErrorMessage={props.hideErrorMessage}
                errorMessageText={props.errorMessageText}
                handleShowImageHelperChange={props.handleShowImageHelperChange}
                handleNavigateBack={props.handleNavigateBack}
                handleShowQuestionnairePasswordChange={props.handleShowQuestionnairePasswordChange}
                showQuestionnairePassword={props.showQuestionnairePassword}
                acceptQuestionnaireFromLoginConsent={props.acceptQuestionnaireFromLoginConsent}
                discardQuestionnaireFromLoginConsent={props.discardQuestionnaireFromLoginConsent}
                handleOnTimeZoneChange={props.handleOnTimeZoneChange}
                handleOnFieldTypeChange={props.handleOnFieldTypeChange}
                supportedTimeZones={props.supportedTimeZones}
                repeatersAllowed={props.repeatersAllowed}
                positiveRepeatCount={props.positiveRepeatCount}
                negativeRepeatCount={props.negativeRepeatCount}
                stepPickerRepeatCount={props.stepPickerRepeatCount}
                inPositiveRepeatMode={props.inPositiveRepeatMode}
                inNegativeRepeatMode={props.inNegativeRepeatMode}
                inStepPickerMode={props.inStepPickerMode}
                handleStepPickerSelection={props.handleStepPickerSelection}
                botStepList={props.botStepList}
                columnFieldList={props.columnFieldList}
                onClickDeleteBotListStep={props.onClickDeleteBotListStep}
                onClickDeleteColumnFieldListStep={props.onClickDeleteColumnFieldListStep}
                hideFrequencyErrorMessage={props.hideFrequencyErrorMessage}
                hideBackButton={props.hideBackButton}
                supportedGolfCourses={props.supportedGolfCourses}
                supportedTennisCourts={props.supportedTennisCourts}
                supportedPickleBallCourts={props.supportedPickleBallCourts}
                supportedCampingSites={props.supportedCampingSites}
                supportedDinnerSites={props.supportedDinnerSites}
                miniBotSearchType={props.miniBotSearchType}
                premiumBotSelectedName={props.premiumBotSelectedName}
                addMiniBotTag={props.addMiniBotTag}
                removeMiniBotTag={props.removeMiniBotTag}
                setTextInputRef={props.setTextInputRef}
                miniBotTags={props.miniBotTags}
                existingDay={props.existingDay}
            />
        }
    </div >
)));