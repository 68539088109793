import React from 'react';
import { inject, observer } from "mobx-react";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Dropdown from 'react-bootstrap/Dropdown'
import onLight from '../../assets/onLight@3x.png';
import scheduleImage from '../../assets/scheule_icon.png';
import trashIcon from '../../assets/trash_icon.png';
import editIcon from '../../assets/edit_item.png';
import unlockImage from '../../assets/onDark@3x.png';
import Jumbotron from 'react-bootstrap/Jumbotron';
import deleteIcon from '../../assets/Delete-icon.png';
import '../../css/createTask.css';
import moment from 'moment'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const format = 'hh:mm A';
const formatSec = 'HH:mm:ss A';

const now = moment().hour(6).minute(0).second(0);


function BotTimePicker(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Create Run Time</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={6}>
                        Select a Time or Frequency:
                    </Col>
                    <Col xs={6}>
                        <Form.Label>Time Zone:</Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TimePicker
                            showSecond={false}
                            onChange={props.onScheduleValueChange}
                            format={formatSec}
                            use12Hours
                            inputReadOnly
                            disabled={props.containsBurst}
                            value={props.runTimes[props.currentSchedulePosition] && moment(props.runTimes[props.currentSchedulePosition].scheduledTime, 'hh:mm:ss A').isValid() ? moment(props.runTimes[props.currentSchedulePosition].scheduledTime, 'hh:mm:ss A') : now}
                        />
                        <Form.Group controlId="burst" hidden={!props.hasAccess}>
                            <Form.Check type="checkbox"
                                checked={props.runTimes[props.currentSchedulePosition] &&
                                    props.runTimes[props.currentSchedulePosition].minuteBurst} label="Burst" onChange={props.handleMinuteBurstChange} />
                        </Form.Group>
                        <Form.Group controlId="infinite" hidden={!props.hasAccess}>
                            <Form.Check type="checkbox"
                                checked={props.runTimes[props.currentSchedulePosition] &&
                                    props.runTimes[props.currentSchedulePosition].infiniteRun} label="Infinite" onChange={props.handleInfiniteRunChange} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="selectTimeZone">

                            <Form.Control disabled={props.containsBurst} as="select" onChange={props.handleOnTimeZoneSelect} >
                                <option value="">--Select--</option>
                                {props.supportedTimeZones.map((zone) => (
                                    <option selected={props.runTimes[props.currentSchedulePosition] && props.runTimes[props.currentSchedulePosition].localTimeZoneId == zone.id}>{zone.id}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Dropdown onSelect={props.onFrequencyValueChange} style={{ marginBottom: '16px' }}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {props.runTimes[props.currentSchedulePosition] && props.runTimes[props.currentSchedulePosition].frequency ? props.runTimes[props.currentSchedulePosition].frequency : "Select Run Frequency"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item disabled={props.containsBurst} eventKey="12 Hours">Run every 12 Hours (Requires Standard Subscription)</Dropdown.Item>
                                <Dropdown.Item disabled={props.containsBurst} eventKey="6 Hours">Run every 6 Hours (Requires Standard Subscription)</Dropdown.Item>
                                <Dropdown.Item disabled={props.containsBurst} eventKey="2 Hours">Run every 2 Hours (Requires Pro Subscription)</Dropdown.Item>
                                <Dropdown.Item disabled={props.containsBurst} eventKey="60 Minutes">Run every 60 Minutes (Requires Pro Subscription)</Dropdown.Item>
                                <Dropdown.Item disabled={props.containsBurst} eventKey="30 Minutes">Run every 30 Minutes (Requires Pro Plus Subscription)</Dropdown.Item>
                                <Dropdown.Item disabled={props.containsBurst} eventKey="15 Minutes">Run every 15 Minutes (Requires Pro Plus Subscription)</Dropdown.Item>
                                <Dropdown.Item disabled={props.containsBurst} eventKey="5 Minutes">Run every 5 Minutes (Requires Elite Subscription)</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <span hidden={props.hideFrequencyErrorMessage} style={{ color: 'red' }}>Please upgrade your subscription for selected frequency</span>

                    </Col>
                </Row>
                <div hidden={!props.hasAccess}>
                    <Row hidden={props.runTimes[props.currentSchedulePosition] && !props.runTimes[props.currentSchedulePosition].frequency}>
                        <Col>
                            <Form.Group controlId="chkAllowWindow" hidden={props.runTimes[props.currentSchedulePosition] && props.runTimes[props.currentSchedulePosition].frequency && (props.runTimes[props.currentSchedulePosition].frequency == "12 Hours" || props.runTimes[props.currentSchedulePosition].frequency == "6 Hours" || props.runTimes[props.currentSchedulePosition].frequency == "2 Hours")}>
                                <Form.Check type="checkbox" checked=
                                    {props.runTimes[props.currentSchedulePosition] &&
                                        props.runTimes[props.currentSchedulePosition].frequencyWindow} label="Time Frame" onChange={props.handleOnCheckFrequencyWindow} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row hidden={props.runTimes[props.currentSchedulePosition] &&
                        !props.runTimes[props.currentSchedulePosition].frequencyWindow}>
                        <Col xs={6}>
                            <Form.Label>Run Between Hours:</Form.Label>
                        </Col>
                    </Row>
                    <Row hidden={props.runTimes[props.currentSchedulePosition] &&
                        !props.runTimes[props.currentSchedulePosition].frequencyWindow} style={{ marginBottom: '20px' }}>
                        <Col xs={1}>
                            <Form.Label>Start:</Form.Label>
                        </Col>
                        <Col>
                            <TimePicker
                                showSecond={false}
                                showMinute={false}
                                onChange={props.onWindowStartHour}
                                format={format}
                                use12Hours
                                inputReadOnly
                                value={props.runTimes[props.currentSchedulePosition] && moment(props.runTimes[props.currentSchedulePosition].frequencyWindowStart, 'hh:mm A').isValid() ? moment(props.runTimes[props.currentSchedulePosition].frequencyWindowStart, 'hh:mm A') : now}
                            />
                        </Col>
                        <Col xs={1}>
                            <Form.Label>End:</Form.Label>
                        </Col>
                        <Col>
                            <TimePicker
                                showSecond={false}
                                showMinute={false}
                                onChange={props.onWindowEndHour}
                                format={format}
                                use12Hours
                                inputReadOnly
                                value={props.runTimes[props.currentSchedulePosition] && moment(props.runTimes[props.currentSchedulePosition].frequencyWindowEnd, 'hh:mm A').isValid() ? moment(props.runTimes[props.currentSchedulePosition].frequencyWindowEnd, 'hh:mm A') : now}
                            />
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col xs={2}>
                        <Form.Group controlId="chkSun">
                            <Form.Check type="checkbox" checked=
                                {props.runTimes[props.currentSchedulePosition] &&
                                    props.runTimes[props.currentSchedulePosition].botRunDays.includes('SUNDAY')} label="Sun" onChange={props.handleOnCheckScheduleChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group controlId="chkMon">
                            <Form.Check checked=
                                {props.runTimes[props.currentSchedulePosition] &&
                                    props.runTimes[props.currentSchedulePosition].botRunDays.includes('MONDAY')} type="checkbox" label="Mon" onChange={props.handleOnCheckScheduleChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group controlId="chkTues">
                            <Form.Check type="checkbox" checked=
                                {props.runTimes[props.currentSchedulePosition] &&
                                    props.runTimes[props.currentSchedulePosition].botRunDays.includes('TUESDAY')} label="Tues" onChange={props.handleOnCheckScheduleChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group controlId="chkWed">
                            <Form.Check type="checkbox" checked=
                                {props.runTimes[props.currentSchedulePosition] &&
                                    props.runTimes[props.currentSchedulePosition].botRunDays.includes('WEDNESDAY')} label="Wed" onChange={props.handleOnCheckScheduleChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group controlId="chkThurs">
                            <Form.Check type="checkbox" checked=
                                {props.runTimes[props.currentSchedulePosition] &&
                                    props.runTimes[props.currentSchedulePosition].botRunDays.includes('THURSDAY')} label="Thurs" onChange={props.handleOnCheckScheduleChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group controlId="chkFri">
                            <Form.Check type="checkbox" checked=
                                {props.runTimes[props.currentSchedulePosition] &&
                                    props.runTimes[props.currentSchedulePosition].botRunDays.includes('FRIDAY')} label="Fri" onChange={props.handleOnCheckScheduleChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group controlId="chkSat">
                            <Form.Check type="checkbox" checked=
                                {props.runTimes[props.currentSchedulePosition] &&
                                    props.runTimes[props.currentSchedulePosition].botRunDays.includes('SATURDAY')} label="Sat" onChange={props.handleOnCheckScheduleChange} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={props.disableScheduleButton} onClick={props.onClickSaveNewSchedule}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function ScheduleDetail(props) {
    return (
        <div>
            <Draggable draggableId={props.id} index={props.index}>
                {provided => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <Row>
                            <Col xs={4}>
                                <span className="text-muted">Run {props.index + 1}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1}>

                            </Col>
                            <Col className="itemMain" xs={8} lg={10}>
                                <RenderScheduleListItem botRunDays={props.botRunDays} time={props.time} frequency={props.frequency} step={props.index}
                                    isModalShowing={props.isModalShowing} />
                            </Col>
                            <div className="actionIcons">
                                <div className="actionIcon">
                                    <a href="#" onClick={() => props.onClickEditSchedule(props.index)}>
                                        <img src={editIcon} alt="" width={32} height={32} />
                                    </a>
                                </div>
                                <div hidden={props.containsBurst} className="actionIcon">
                                    <a href="#" onClick={() => props.onDeleteScheduleItem(props.index)}>
                                        <img src={deleteIcon} alt="" width={32} height={32} />
                                    </a>
                                </div>
                            </div>
                        </Row>

                    </div>
                )}
            </Draggable>
            {props.index == props.scheduleSize - 1 && !props.hideAddTimeButton &&
                <div>
                    <Row>
                        <Col xs={1}></Col>
                        {props.showUpgradeButton() ? (
                            <Col sm={10} style={{ textAlign: 'center', marginTop: '20px' }}>
                                <Button block onClick={() => { window.location = '/subscription' }} style={{ backgroundColor: 'rgb(255 119 0)' }}><i><img height={25} width={24} src={unlockImage} /></i> Upgrade to unlock more run times</Button>
                            </Col>
                        ) : (
                            <Col hidden={props.containsBurst} sm={10} className="itemNew" style={{ textAlign: 'center', marginTop: '20px' }}>
                                <a onClick={() => props.handleAddTimeModalShow(true, true)}>
                                    <div>
                                        <div>

                                            <span className="itemNewTitle text-muted">Add Time</span>
                                        </div>
                                    </div>
                                </a>
                            </Col>
                        )}

                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <div style={{ textAlign: 'left' }}>
                                <Button variant="primary" onClick={props.onClickStepBack}>Back</Button>
                            </div>
                        </Col>
                        <Col>
                            <div style={{ textAlign: 'right' }}>
                                <Button variant="primary" onClick={props.onClickSaveBotData} disabled={props.disableSaveButton}>{props.saveButtonText}</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </div>

    );
}


function RenderScheduleListItem(props) {
    return <div style={{ marginTop: '7px' }}>
        <img className="itemImg" src={onLight} alt="" />
        <span className="scheduleTitle">{props.time ? props.time.format('hh:mm:ss A') : "Every " + props.frequency} {props.botRunDays &&
            <span>({props.botRunDays && props.botRunDays.length > 0 ?
                (props.botRunDays.indexOf(',') == -1 ? "1" : (props.botRunDays.split(',').filter(x => x).length)) + " days" : ""})</span>
        }</span>
    </div>
}

const ScheduleList = React.memo(function ScheduleList({ runTimes, handleAddTimeModalShow, hideAddTimeButton, onClickEditSchedule, onDeleteScheduleItem,
    onClickStepBack, onClickSaveBotData, saveButtonText, disableSaveButton, isModalShowing, showUpgradeButton, containsBurst }) {
    return runTimes.map((time, index) => (
        <ScheduleDetail time={runTimes[index].scheduledTime ? moment(runTimes[index].scheduledTime, 'hh:mm:ss A') : null} frequency={runTimes[index].frequency} botRunDays={runTimes[index].botRunDays}
            index={index} id={'id-' + index} scheduleSize={runTimes.length} handleAddTimeModalShow={handleAddTimeModalShow}
            hideAddTimeButton={hideAddTimeButton} onClickEditSchedule={onClickEditSchedule} onDeleteScheduleItem={onDeleteScheduleItem} onClickStepBack={onClickStepBack}
            onClickSaveBotData={onClickSaveBotData} saveButtonText={saveButtonText} disableSaveButton={disableSaveButton}
            isModalShowing={isModalShowing} showUpgradeButton={showUpgradeButton} containsBurst={containsBurst}
        />
    ));

});


function ConfirmScheduleDelete(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group controlId="smsDetails">
                                <Form.Label>Are you sure you want to delete {props.pendingDeleteSchedule.message}?</Form.Label>
                            </Form.Group>
                        </Form>
                    </Col>

                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onClickConfirmDeleteSchedule}>
                    Yes Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export const StepFourSchedule = inject("rootStore")(observer((props) => (
    <div>
        <Jumbotron hidden={props.step == 4 ? false : true} style={{ backgroundColor: 'rgb(244 248 250)' }}>
            <div >
                {
                    props.runTimes.length == 0 ?
                        (
                            <div style={{ marginTop: "30px" }}>
                                <Row style={{ marginTop: '20px', textAlign: "center" }} >
                                    <Col xs={12}>
                                        <h3>Schedule a Time to Run</h3>
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "center" }} >
                                    <Col xs={12}>
                                        <img src={scheduleImage} />
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "center", marginTop: '20px' }} >
                                    <Col xs={12}>
                                        {props.showUpgradeButton() ? (
                                            <Button onClick={() => { window.location = '/subscription' }} style={{ backgroundColor: 'rgb(255 119 0)' }}><i><img height={25} width={24} src={unlockImage} /></i> Upgrade to unlock more run times</Button>
                                        ) : (
                                            <Button type="button" onClick={() => props.handleAddTimeModalShow(true, true)}>Add a time</Button>
                                        )}

                                    </Col>

                                </Row>
                            </div>
                        ) : (
                            <div>
                                <DragDropContext onDragEnd={props.onDragEnd} onDragStart={props.onDragStart}>
                                    <Droppable droppableId="list">
                                        {provided => (
                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                <ScheduleList runTimes={props.runTimes} scheduleSize={props.runTimes.length}
                                                    handleAddTimeModalShow={props.handleAddTimeModalShow} hideAddTimeButton={props.hideAddTimeButton}
                                                    onClickEditSchedule={props.onClickEditSchedule} onDeleteScheduleItem={props.onDeleteScheduleItem} onClickStepBack={props.onClickStepBack}
                                                    onClickSaveBotData={props.onClickSaveBotData} saveButtonText={props.saveButtonText}
                                                    disableSaveButton={props.disableSaveButton} isModalShowing={props.addScheduleModalShow}
                                                    showUpgradeButton={props.showUpgradeButton} containsBurst={props.containsBurst} />
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>

                            </div>
                        )
                }
                <BotTimePicker
                    show={props.addScheduleModalShow}
                    onHide={() => props.handleAddTimeModalShow(false, false, true)}
                    supportedTimeZones={props.supportedTimeZones}
                    handleOnTimeZoneSelect={props.handleOnTimeZoneSelect}
                    handleOnCheckScheduleChange={props.handleOnCheckScheduleChange}
                    onClickSaveNewSchedule={props.onClickSaveNewSchedule}
                    onScheduleValueChange={props.onScheduleValueChange}
                    disableScheduleButton={props.disableScheduleButton}
                    currentSchedulePosition={props.currentSchedulePosition}
                    runTimes={props.runTimes}
                    onFrequencyValueChange={props.onFrequencyValueChange}
                    hideFrequencyErrorMessage={props.hideFrequencyErrorMessage}
                    handleOnCheckFrequencyWindow={props.handleOnCheckFrequencyWindow}
                    handleMinuteBurstChange={props.handleMinuteBurstChange}
                    handleInfiniteRunChange={props.handleInfiniteRunChange}
                    onWindowStartHour={props.onWindowStartHour}
                    onWindowEndHour={props.onWindowEndHour}
                    emailAddress={props.emailAddress}
                    containsBurst={props.containsBurst}
                    hasAccess={props.hasAccess}
                />
                {
                    props.runTimes.length > 0 &&
                    <div>
                        <ConfirmScheduleDelete
                            show={props.confirmDeleteScheduleModalShow}
                            onHide={() => props.handleConfirmDeleteScheduleShow(false, true)}
                            onClickConfirmDeleteSchedule={props.onClickConfirmDeleteSchedule}
                            pendingDeleteSchedule={props.pendingDeleteSchedule}
                        />
                    </div>}
            </div >
        </Jumbotron>
        {props.dragInProgressSchedule &&
            <Row className="trashItem">
                <Col style={{ textAlign: 'center' }}>
                    <img src={trashIcon} />
                </Col>
            </Row>
        }
    </div>

)));

