import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Calendar from 'react-calendar';
export const PickDate = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col>
                    <Form.Group style={{ textAlign: 'center' }}>
                        <Form.Label>Select a future date</Form.Label>
                        <div className="d-flex justify-content-center">
                            <Calendar
                                minDate={new Date()}
                                onChange={props.questionObj.datePickerValueChange(
                                    props.questionObj.questionPlacement)}
                                value={props.questionObj.datePicker ? props.questionObj.datePicker : new Date()}
                            />
                        </div>

                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Which Calendar on the Page?</Form.Label>
                        <Form.Control placeholder="Calendar Name" autocomplete="off" onChange={props.questionObj.handleAnswer1TextChange(
                            props.questionObj.questionPlacement)} hidden={props.questionObj.question.autoLabel ? true : false} value={props.questionObj.answer1} type="text" required />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label>Repeat</Form.Label>
                    <Form.Group>
                        <Form.Control onChange={props.questionObj.handleOnDatePickerRepeatOnChange} as="select">
                            {props.questionObj.repeatersAllowed.map((item) => (
                                <option selected={props.questionObj.answer2 == item}>{item}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="btn btn-primary" disabled={props.questionObj.isAnswerButtonDisabled} onClick={props.questionObj.navigateQuestionnaire(props.questionObj.steps)} block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div>
    )
}));