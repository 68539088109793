import React, { Component } from 'react';
import { Formik } from "formik";
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import bwLogo from '../../assets/Logo_BnW@3x.png';
import ApiClient from '../../util/ApiClient';
import { getParameterByName } from '../../util/queryString';

@inject('rootStore')
@observer
export default class Verify extends Component {
    constructor() {
        super();
        this.state = {
            apiError: '',
            type: 0,
            botId: '',
            authVerifyUrl: 'verify/authentication?botId=',
        };
    }

    componentDidMount() {
        var botId = getParameterByName('vcx', window.location.href);
        var t = getParameterByName('t', window.location.href);
        if (!botId && !t) {
            window.alert("Please try again");
            window.location = '/'
            return;
        }
        this.setState({ type: t, botId: botId });
    }
    render() {

        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={12}>
                        <Jumbotron style={{ backgroundColor: 'rgb(255 215 0)' }}>
                            <Row>
                                <Col style={{ textAlign: "center", marginBottom: "20px" }}>
                                    <img src={bwLogo} width="180px" height="90px" style={{ paddingRight: '10px' }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "center" }}>
                                    <h3>Authorize Your Bot</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row style={{ margin: "20px" }}>
                                        <Col>
                                            {this.state.type && this.state.type == 0 &&
                                                <Formik
                                                    initialValues={{
                                                        allowed: true
                                                    }}
                                                    onSubmit={(values, actions) => {
                                                        ApiClient.post('bot/auth/access/allow', { botId: this.state.botId }).then((response) => {
                                                            if (response.status == 200) {
                                                                actions.setStatus('Access Granted. No further action required.');
                                                                actions.setSubmitting(false);
                                                                actions.setValues(values, false);
                                                            }
                                                            else {
                                                                actions.setSubmitting(false);
                                                                actions.setValues(values, false);
                                                                this.setState({ apiError: "Error granting access. Please try again" })
                                                            }
                                                        }).catch((error) => {

                                                        });
                                                    }}
                                                >
                                                    {formik => (
                                                        <div>
                                                            <Form onSubmit={formik.handleSubmit}>
                                                                <Form.Group controlId="code">
                                                                    <h2 style={{ textAlign: 'center' }}>Click the button below after you grant your bot access.</h2>
                                                                    <Button variant='success' type='submit' block>Access Granted</Button>
                                                                </Form.Group>
                                                                <Row style={{ textAlign: 'center' }}>
                                                                    <Col lg={12}>
                                                                        <span style={{ color: 'green' }}>{formik.status}{formik.status && <div><br /><a href="/"> View My Bots</a></div>}</span>
                                                                    </Col>
                                                                </Row>
                                                                <Row style={{ textAlign: 'center' }}>
                                                                    <Col lg={12}>
                                                                        <span style={{ color: 'red', margin: '20px' }}>{this.state.apiError}</span>
                                                                    </Col>
                                                                </Row>

                                                            </Form>
                                                        </div>
                                                    )}
                                                </Formik>
                                            }
                                            {this.state.type && (this.state.type == 1 || this.state.type == 3) &&
                                                <Formik
                                                    initialValues={{
                                                        code: ""
                                                    }}
                                                    onSubmit={(values, actions) => {
                                                        ApiClient.post('bot/auth/access/code', { botId: this.state.botId, code: values.code }).then((response) => {
                                                            if (response.status == 200) {
                                                                actions.setStatus('Access Granted. No further action required.');
                                                                actions.setSubmitting(false);
                                                                actions.setValues(values, false);
                                                            }
                                                            else {
                                                                actions.setSubmitting(false);
                                                                actions.setValues(values, false);
                                                                this.setState({ apiError: "Error granting access. Please try again" })
                                                            }
                                                        }).catch((error) => {
                                                            actions.setSubmitting(false);
                                                            actions.setValues(values, false);
                                                            this.setState({ apiError: "Error granting access. Please try again" })
                                                        });
                                                    }}
                                                >
                                                    {formik => (
                                                        <div>
                                                            <Form onSubmit={formik.handleSubmit}>
                                                                <Form.Group controlId="code">
                                                                    <h2 style={{ textAlign: 'center' }}>One Time Authorization Code.</h2>
                                                                    <Form.Control onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.code} type="code" placeholder="Enter Code" required />
                                                                </Form.Group>
                                                                <Row style={{ textAlign: 'center' }}>
                                                                    <Col lg={12}>
                                                                        <Button variant="success" type="submit" disabled={formik.isSubmitting}> {"Send Code"}</Button>
                                                                    </Col>
                                                                </Row>
                                                                <Row style={{ textAlign: 'center' }}>
                                                                    <Col lg={12}>
                                                                        <span style={{ color: 'green' }}>{formik.status}{formik.status && <div><br /><a href="/"> View My Bots</a></div>}</span>
                                                                    </Col>
                                                                </Row>
                                                                <Row style={{ textAlign: 'center' }}>
                                                                    <Col lg={12}>
                                                                        <span style={{ color: 'red', margin: '20px' }}>{this.state.apiError}</span>
                                                                    </Col>
                                                                </Row>

                                                            </Form>
                                                        </div>
                                                    )}
                                                </Formik>
                                            }
                                            {this.state.type && this.state.type == 2 &&
                                                <Formik
                                                    initialValues={{
                                                        url: false
                                                    }}
                                                    onSubmit={(values, actions) => {
                                                        ApiClient.post('bot/auth/access/url', { botId: this.state.botId, url: values.url }).then((response) => {
                                                            if (response.status == 200) {
                                                                actions.setStatus('Access Granted. No further action required.');
                                                                actions.setSubmitting(false);
                                                                actions.setValues(values, false);
                                                            }
                                                            else {
                                                                actions.setSubmitting(false);
                                                                actions.setValues(values, false);
                                                                this.setState({ apiError: "Error granting access. Please try again" })
                                                            }
                                                        }).catch((error) => {

                                                        });
                                                    }}>
                                                    {formik => (
                                                        <div>
                                                            <Form onSubmit={formik.handleSubmit}>
                                                                <Form.Group controlId="url">
                                                                    <h2 style={{ textAlign: 'center' }}>One Time Verification Link</h2>
                                                                    <Form.Control as="textarea" rows={2} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                                </Form.Group>
                                                                <Row style={{ textAlign: 'center' }}>
                                                                    <Col lg={12}>
                                                                        <Button variant="success" type="submit" placeholder='https://verification.12345.link.com' disabled={formik.isSubmitting}> {"Send URL"}</Button>
                                                                    </Col>
                                                                </Row>
                                                                <Row style={{ textAlign: 'center' }}>
                                                                    <Col lg={12}>
                                                                        <span style={{ color: 'red', margin: '20px' }}>{this.state.apiError}</span>
                                                                    </Col>
                                                                </Row>
                                                                <Row style={{ textAlign: 'center' }}>
                                                                    <Col lg={12}>
                                                                        <span style={{ color: 'green' }}>{formik.status}{formik.status && <div><br /><a href="/"> View My Bots</a></div>}</span>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        </div>
                                                    )}
                                                </Formik>
                                            }
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </Jumbotron>
                    </Col>
                </Row>
            </Container >
        )
    }
}