import React from 'react';
import { inject, observer } from "mobx-react";
import darkLogin from '../../../assets/onDarkLogin@3x.png';
import darkInputField from '../../../assets/onDarkInputField@3x.png';
import darkClick from '../../../assets/onDarkClick@3x.png';
import selectMenu from '../../../assets/EZ-Menu-icon.png';
import calendarIcon from '../../../assets/calendar-icon.png';
import clockIcon from '../../../assets/Clock-icon.png';
import creditCard from '../../../assets/Credit-Card-2-icon.png';
import listIcon from '../../../assets/Bulleted-List-icon.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
export const StepPicker = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col md={5} className="taskListItem">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Automate typing your username and password and clicking the login button.</Tooltip>}>
                        <span onClick={props.questionObj.handleStepPickerSelection("LOGIN")} >
                            <Row style={{ marginTop: '11px' }}>
                                <Col xs={2}>
                                    <img src={darkLogin} width="24" height="24" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Login</h5>
                                </Col>
                            </Row>
                        </span>
                    </OverlayTrigger>
                </Col>
                <Col md={5} className="taskListItem">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Automate typing in any field.</Tooltip>}>
                        <span onClick={props.questionObj.handleStepPickerSelection("TYPING")} >
                            <Row style={{ marginTop: '11px' }}>
                                <Col xs={2}>
                                    <img src={darkInputField} width="24" height="24" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Type or Search</h5>
                                </Col>
                            </Row>
                        </span>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row>
                <Col md={5} className="taskListItem">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Automate clicking any button or checkbox.</Tooltip>}>
                        <span onClick={props.questionObj.handleStepPickerSelection("BUTTONCLICK")} >
                            <Row style={{ marginTop: '11px' }}>
                                <Col xs={2}>
                                    <img src={darkClick} width="24" height="24" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Click Button</h5>
                                </Col>
                            </Row>
                        </span>
                    </OverlayTrigger>
                </Col>
                <Col md={5} className="taskListItem">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Automatically select an item from a search result list.</Tooltip>}>
                        <span onClick={props.questionObj.handleStepPickerSelection("LISTSELECTOR")} >
                            <Row style={{ marginTop: '11px' }}>
                                <Col xs={2}>
                                    <img src={listIcon} width="24" height="24" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Select List Item</h5>
                                </Col>
                            </Row>
                        </span>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row>
                <Col md={5} className="taskListItem">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Automate selecting a date on a calendar.</Tooltip>}>
                        <span onClick={props.questionObj.handleStepPickerSelection("PICKDATE")} >
                            <Row style={{ marginTop: '11px' }}>
                                <Col xs={2}>
                                    <img src={calendarIcon} width="24" height="24" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Pick a Date</h5>
                                </Col>
                            </Row>
                        </span>
                    </OverlayTrigger>
                </Col>
                <Col md={5} className="taskListItem">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Automate selecting an option from a Drop Down List.</Tooltip>}>
                        <span onClick={props.questionObj.handleStepPickerSelection("OPTION")} >
                            <Row style={{ marginTop: '11px' }}>
                                <Col xs={2}>
                                    <img src={selectMenu} width="24" height="24" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Drop Down List</h5>
                                </Col>
                            </Row>
                        </span>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row>
                <Col md={5} className="taskListItem">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Pick a time to associate with a search or booking.</Tooltip>}>
                        <span onClick={props.questionObj.handleStepPickerSelection("PICKTIME")} >
                            <Row style={{ marginTop: '11px' }}>
                                <Col xs={2}>
                                    <img src={clockIcon} width="24" height="24" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Pick a time</h5>
                                </Col>
                            </Row>
                        </span>
                    </OverlayTrigger>
                </Col>
                <Col md={5} className="taskListItem" hidden={props.questionObj.currentSeriesId == "605c38336f04a82ed6d6b9bd"}>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Automatically add payment information to a website.</Tooltip>}>
                        <span onClick={props.questionObj.handleStepPickerSelection("CreditCard")} >
                            <Row style={{ marginTop: '11px' }}>
                                <Col xs={2}>
                                    <img src={creditCard} width="24" height="24" />
                                </Col>
                                <Col xs={10}>
                                    <h5>Credit Card</h5>
                                </Col>
                            </Row>
                        </span>
                    </OverlayTrigger>
                </Col>
            </Row>
        </div>
    )
}));