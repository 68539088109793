import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Jumbotron from 'react-bootstrap/Jumbotron';
import urlHelperImage from '../../assets/url_helper.png';
import Modal from 'react-bootstrap/Modal'

function UrlHelperModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Getting Started</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={12}>
                        <img className="mx-auto d-block" width={325} height={144} src={urlHelperImage} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            1. In your browser, go to the specific page where you want your Bot to start.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            2. Then copy the link and return here to Bot-It.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            <Button variant="primary" href="https://botit.com" target="_blank" type="button" className="btn btn-primary" block>Open Browser Tab</Button>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="link" onClick={props.hideUrlHelperModal} type="button" href="">Done</Button>
                    </Col>
                    <Col>
                        <Form.Group controlId="chkDontShowAgain">
                            <Form.Check type="checkbox" label="Don't Show Again" onChange={props.handleDontShowUrlHelperChange} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
export const StepOneUrl = inject("rootStore")(observer((props) => (
    <div hidden={props.step == 1 ? false : true}>
        <Jumbotron style={{ backgroundColor: 'rgb(244 248 250)' }}>
            <Row>
                <Col style={{ textAlign: 'center', marginTop: '30px' }}>
                    <h5>Step 1: Insert the website link where your Bot will start.</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="botUrl">
                        <Form.Label>Web Url</Form.Label>
                        <Form.Control autocomplete="off" onChange={props.handleUrlChange} value={props.botUrl} type="url" required />
                        <Form.Text className="text-muted">
                            Accept clipboard permission and we will automatically insert your provided website.
                        </Form.Text>
                    </Form.Group>
                    <Row>
                        <Col>
                            <div style={{ textAlign: 'left' }}>
                                <Button variant="primary" type="button" disabled={true}> Back</Button>
                            </div>
                        </Col>
                        <Col>
                            <div style={{ textAlign: 'right' }}>
                                <Button variant="primary" type="button" onClick={props.onSaveUrlNextClick} disabled={props.disableStep1Button}> Next</Button>
                                <Form.Label style={{ color: 'red', marginLeft: '20px' }}>{props.errorText}</Form.Label>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Jumbotron>
        <UrlHelperModal
            show={props.showUrlHelper}
            onHide={() => props.handleShowUrlHelper(false)}
            hideUrlHelperModal={() => props.handleShowUrlHelper(false)}
            handleDontShowUrlHelperChange={props.handleDontShowUrlHelperChange}
        />
    </div >
)));
