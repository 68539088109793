import React from 'react';
import { inject, observer } from "mobx-react";
import '../css/templates.css';
import { Col } from 'react-bootstrap';

export const Templates = inject("rootStore")(observer((props) => {
    return (
        <div className='templateItems' hidden={props.isEditing} style={{ marginTop: '15px' }}>
            <div className="row">
                <Col className='col col-item' hidden={props.templateOptions && props.templateOptions.length == 0}>
                    <a href='#' style={{ textDecoration: 'none' }} onClick={() =>
                        props.startSeries(props.preSubState ? props.customTemplateOptions[0].id : props.customTemplateOptions[1].id,
                            props.preSubState ? props.customTemplateOptions[0].title : props.customTemplateOptions[1].title,
                            props.preSubState ? props.customTemplateOptions[0].description : props.customTemplateOptions[1].description,)}>
                        <div className="styles_cardContainer__v2_eP  styles_defaultCardBackground__U7NNW styles_firstCard__7YL4W minibotTemplates">
                            <svg className="styles_plusIcon__Nggcw" xmlns="http://www.w3.org/2000/svg" width="97" height="96" viewBox="0 0 97 96" fill="none">
                                <path d="M48.6523 28.1025V67.9648" stroke="#1F1E1B" stroke-width="3.5876" stroke-linecap="square" stroke-linejoin="round"></path>
                                <path d="M28.7212 48.0342H68.5834" stroke="#1F1E1B" stroke-width="3.5876" stroke-linecap="square" stroke-linejoin="round"></path>
                            </svg>
                            <span className="styles_cardLabel__SqmcF styles_defaultCardLabel__kXiom">Custom Bot</span>
                        </div>
                    </a>
                </Col>
                {props.templateOptions && props.templateOptions.map((template) => {
                    return props.preSubState && template.minSubscription == "STARTER" ?
                        <Col className='col col-item'>
                            <a style={{ textDecoration: 'none' }} onClick={() => props.goToCreate == true ?
                                window.location = ("/create?series=" + template.id) :
                                props.startSeries(template.id, template.title, template.description, template.questionnaireType)} href="#">  <div class="styles_cardContainer__v2_eP   undefined"><div id="dummy_to_keep_spacing"></div>
                                    <span class="styles_cardLabel__SqmcF styles_cardLabelColor__DNbYJ">{template.title}</span>
                                    <img alt="Golf" src={template.webImageUrl} />
                                </div> </a>
                        </Col> :
                        !props.preSubState && template.minSubscription != "STARTER" &&
                        <Col className='col col-item'>
                            <a style={{ textDecoration: 'none' }} onClick={() => props.goToCreate == true ?
                                window.location = ("/create?series=" + template.id) :
                                props.startSeries(template.id, template.title, template.description, template.questionnaireType)} href="#">  <div class="styles_cardContainer__v2_eP   undefined"><div id="dummy_to_keep_spacing"></div>
                                    <span class="styles_cardLabel__SqmcF styles_cardLabelColor__DNbYJ">{template.title}</span>
                                    <img alt="Golf" src={template.webImageUrl} />
                                </div> </a>
                        </Col>
                }

                )}
            </div>

        </div>
    )
}));