import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import { getParameterByName } from '../../util/queryString';
import Button from 'react-bootstrap/Button'
import ApiClient from '../../util/ApiClient';
@inject('rootStore')
@observer
export default class ConfirmEmail extends Component {
    constructor() {
        super();
        this.state = {
            apiError: ''
        };
    }
    componentDidMount() {
        const rootStore = this.props.rootStore;
        var confirmationCode = getParameterByName('code', window.location.href);
        ApiClient.get('user/confirm/email/' + confirmationCode).then((response) => {
        }).catch((error) => { });
    }
    onClickGoHomeRedirect = () => {

        window.location = '/'
    }
    render() {

        const rootStore = this.props.rootStore;

        return (
            <Container>
                <Row style={{textAlign:'center'}}>
                    <Col lg={12}>
                        <h2>Thank you for confirming your account. You can continue in the Bot-It app or click return home for web.</h2>
                    </Col>
                </Row>
                <Row style={{justifyContent:'center'}}>
                    <Col xs={5}>
                        <Button variant="primary" onClick={this.onClickGoHomeRedirect} block>Return Home</Button>
                    </Col>
                </Row>
            </Container>
        )
    }
}