import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import TimePicker from 'rc-time-picker';
import moment from 'moment'
import Dropdown from 'react-bootstrap/Dropdown'
const format = 'hh:mm A';
const formatSec = 'hh:mm:ss A';

const now = moment().hour(10).minute(0).second(0);
export const QuestionnaireTimePicker = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col style={{ textAlign: 'center' }}>
                    <Form.Group>
                        <TimePicker
                            showSecond={false}
                            onChange={props.questionObj.handleTimePickerOnValueChange(props.questionObj.questionPlacement)}
                            format={formatSec}
                            use12Hours
                            inputReadOnly
                            value={props.questionObj.time ? moment(props.questionObj.time, 'hh:mm:ss A') : now}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row><Col style={{ textAlign: 'center' }}>
                <h4>OR</h4>
            </Col></Row>
            <Row>
                <Col style={{ textAlign: 'center' }}>
                    <Dropdown onSelect={props.questionObj.handleAnswer1TextChange(props.questionObj.questionPlacement, props.questionObj.question.positiveQuestionType, true)} style={{ margin: '13px 0', textAlign: 'center' }}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {props.questionObj.answer1 ? props.questionObj.answer1 : "Select Run Frequency"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="12 Hours">Every 12 Hours (Requires Standard Subscription)</Dropdown.Item>
                            <Dropdown.Item eventKey="6 Hours">Every 6 Hours (Requires Standard Subscription)</Dropdown.Item>
                            <Dropdown.Item eventKey="2 Hours">Every 2 Hours (Requires Pro Subscription)</Dropdown.Item>
                            <Dropdown.Item eventKey="60 Minutes">Every 60 Minutes (Requires Pro Subscription)</Dropdown.Item>
                            <Dropdown.Item eventKey="30 Minutes">Every 30 Minutes (Requires Premium Subscription)</Dropdown.Item>
                            <Dropdown.Item eventKey="15 Minutes">Every 15 Minutes (Requires Premium Subscription)</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <span hidden={props.questionObj.hideFrequencyErrorMessage} style={{ color: 'red' }}>Please upgrade your subscription for selected frequency</span>

                </Col>
            </Row>
            <Row hidden={props.questionObj && !props.questionObj.answer1}>
                <Col>
                    <Form.Group controlId="chkAllowWindow" hidden={props.questionObj && props.questionObj.answer1 && (props.questionObj.answer1 == "12 Hours" || props.questionObj.answer1 == "6 Hours" || props.questionObj.answer1 == "2 Hours")}>
                        <Form.Check type="checkbox" checked=
                            {props.questionObj.yesNo1} label="Time Frame" onChange={props.questionObj.handleYesNo1Change} />
                    </Form.Group>
                </Col>
            </Row>
            <Row hidden={props.questionObj &&
                !props.questionObj.yesNo1}>
                <Col xs={6}>
                    <Form.Label>Run Between Hours:</Form.Label>
                </Col>
            </Row>
            <Row hidden={props.questionObj &&
                !props.questionObj.yesNo1} style={{ marginBottom: '20px' }}>
                <Col xs={1}>
                    <Form.Label>Start:</Form.Label>
                </Col>
                <Col>
                    <TimePicker
                        showSecond={false}
                        showMinute={false}
                        onChange={props.questionObj.onSeriesWindowStartHour(props.questionObj.questionPlacement)}
                        format={format}
                        use12Hours
                        inputReadOnly
                        value={props.questionObj.answer2 && moment(props.questionObj.answer2, 'hh:mm A').isValid() ? moment(props.questionObj.answer2, 'hh:mm A') : now}
                    />
                </Col>
                <Col xs={1}>
                    <Form.Label>End:</Form.Label>
                </Col>
                <Col>
                    <TimePicker
                        showSecond={false}
                        showMinute={false}
                        onChange={props.questionObj.onSeriesWindowEndHour(props.questionObj.questionPlacement)}
                        format={format}
                        use12Hours
                        inputReadOnly
                        value={props.questionObj.answer3 && moment(props.questionObj.answer3, 'hh:mm A').isValid() ? moment(props.questionObj.answer3, 'hh:mm A') : now}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="btn btn-primary" disabled={props.questionObj.isAnswerButtonDisabled} onClick={props.questionObj.navigateQuestionnaire(props.questionObj.steps)} block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div>
    )
}));