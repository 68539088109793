import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
export const SpreadsheetColumn = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col>
                    <Form.Group >
                        <Form.Control placeholder="Spreadsheet column name" autocomplete="off" onChange={props.questionObj.handleAnswer3TextChange(
                            props.questionObj.questionPlacement, 0, props.questionObj.question.positiveQuestionType, true)}
                            value={props.questionObj.answer1}
                            type="text" required />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Control placeholder="Field name on website form" autocomplete="off" onChange={props.questionObj.handleAnswer3TextChange(
                            props.questionObj.questionPlacement, 1, props.questionObj.question.positiveQuestionType, true)} value={props.questionObj.answer2} type="text" required />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Field type on website form:</Form.Label>
                        <Form.Control onChange={props.questionObj.handleOnFieldTypeChange} as="select">
                            <option selected={true}>--</option>
                            <option >Text</option>
                            <option>Drop Down</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="btn btn-primary" disabled={props.questionObj.isAnswerButtonDisabled} onClick={props.questionObj.navigateQuestionnaire(props.questionObj.steps)} block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div>
    )
}));