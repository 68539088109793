import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import { getParameterByName } from '../../util/queryString';
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import ApiClient from '../../util/ApiClient';
@inject('rootStore')
@observer
export default class ApprovalView extends Component {
    constructor() {
        super();
        this.state = {
            apiError: '',
            approvalStatus: '',
            ticketId: '',
            approvalBotId: '?chainBoId=66ab63352c10701aa4bec420&chainBotParams=ID%3AID%2CURL%3AURL%2CPOLICY_ID%3APOLICY_ID',
            ticketCommentBotId: '66ab61622c10701aa4bec40b',
            approver: '',
            requestor: '',
            url: '',
            deniedMessage: 'Hi {REQUESTOR_NAME}, You’re request to allow {URL} has been {STATUS} by {APPROVER_NAME}. This ticket will close automatically.',
            approvalMessage: 'Hi {REQUESTOR_NAME}, You’re request to allow {URL} has been {STATUS} by {APPROVER_NAME}.  Please allow 15-20 minutes for the change to to take effect.  This ticket will close automatically.',
            hideConfirmationAlert: true,
            policyId: ''
        };
    }
    componentDidMount() {
        const rootStore = this.props.rootStore;
        var status = getParameterByName('status', window.location.href);
        var id = getParameterByName('id', window.location.href);
        var approverName = getParameterByName('approver_name', window.location.href);
        var approveUrl = getParameterByName('url', window.location.href);
        var req = getParameterByName('requestor_name', window.location.href);
        var policy = getParameterByName('policy_id', window.location.href);
        this.setState({ approvalStatus: status, ticketId: id, approver: approverName, url: approveUrl, requestor: req, policyId: policy }, () => {
            this.startApproval();
        })
    }
    startApproval = () => {
        if (this.state.approvalStatus == "approve") {
            ApiClient.post('elite/start/' + this.state.ticketCommentBotId + this.state.approvalBotId,
                { data: { ID: this.state.ticketId, STATUS: this.state.approvalStatus, URL: this.state.url, APPROVER_NAME: this.state.approver, REQUESTOR_NAME: this.state.requestor, COMMENT_MESSAGE: this.state.approvalMessage, POLICY_ID: this.state.policyId } }).then((response) => {
                    this.setState({ hideConfirmationAlert: false })
                }).catch((error) => { });
        }
        else {
            ApiClient.post('elite/start/' + this.state.ticketCommentBotId, {
                data: {
                    ID: this.state.ticketId, STATUS: this.state.approvalStatus,
                    URL: this.state.url, APPROVER_NAME: this.state.approver, REQUESTOR_NAME: this.state.requestor,
                    COMMENT_MESSAGE: this.state.deniedMessage
                }
            }).then((response) => {
                this.setState({ hideConfirmationAlert: false })
            }).catch((error) => { });
        }
    }
    render() {

        const rootStore = this.props.rootStore;

        return (
            <Container>
                <Row style={{ textAlign: 'center' }}>
                    <Col lg={12}>
                        <h2>{"Requested By: " + this.state.requestor}</h2>
                    </Col>
                </Row>
                <Row style={{ textAlign: 'center' }}>
                    <Col lg={12}>
                        <h2>{"Status: " + this.state.approvalStatus.toUpperCase()}</h2>
                    </Col>
                </Row>
                <Row style={{ textAlign: 'center' }}>
                    <Col lg={12}>
                        <h2>{"Website: " + this.state.url}</h2>
                    </Col>
                </Row>
                <Row style={{ textAlign: 'center' }}>
                    <Col lg={12}>
                        <h2>{"Ticket ID: " + this.state.ticketId}</h2>
                    </Col>
                </Row>
                <Row hidden={this.state.hideConfirmationAlert} style={{ justifyContent: 'center', marginTop: '12px' }}>
                    <Col xs={5}>
                        <Alert variant={"success"} >
                            Confirmation complete. You can now close this tab.
                        </Alert>
                    </Col>
                </Row>
            </Container>
        )
    }
}