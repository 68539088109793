import React, { Component } from 'react';
import './App.css';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Login from './pages/login/Login';
import Share from './pages/register/ShareBot';
import Referral from './pages/register/Referral';
import UserHome from './pages/home/UserHome';
import Create from './pages/create/Create';
import Register from './pages/register/Register';
import FGRegister from './pages/register/ForGalsRegister';
import ConfirmEmail from './pages/register/ConfirmEmail';
import ResetPassword from './pages/register/ResetPassword';
import Subscribe from './pages/subscribe/Subscribe';
import Support from './pages/contact/Support';
import Business from './pages/business/View';
import Approval from './pages/business/Approval';
import AuthVerify from './pages/verifyTwoFormAuth/Verify';
import { AppBar } from './components/AppBar';

const checkAuth = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }

  try {
    return true;

  } catch (e) {
    return false;
  }
}
const clearData = () => {
  localStorage.clear()
  window.location = '/'
}
const AuthRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    checkAuth() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    )
  )} />
)
const logout = ({ component: Component, ...rest }) => (
  clearData()
)

@inject('rootStore')
@observer
class App extends Component {
  componentWillMount() {

    const rootStore = this.props.rootStore;

  }
  showNavBar = () => {

    const pathname = window.location.pathname;
    if (pathname === "/login" || pathname === "/register" || pathname === "/reset/password") {
      return false;
    }
    return true;
  }
  render() {
    const rootStore = this.props.rootStore;
    return (
      <div>
        {this.showNavBar() && <AppBar />}
        <main role="main" style={{ padding: '1rem',backgroundColor:'#fffdf9' }}>
          <Switch>
            <AuthRoute exact path="/" component={UserHome} />
            <Route exact path="/s" render={props => <Share {...props} />} />
            <Route exact path="/u" render={props => <Referral {...props} />} />
            <Route exact path="/login" render={props => <Login {...props} />} />
            <Route exact path="/register" render={props => <Register {...props} />} />
            <Route exact path="/foregalsgolf" render={props => <FGRegister {...props} />} />
            <Route exact path="/confirm" render={props => <ConfirmEmail {...props} />} />
            <Route exact path="/reset/password" render={props => <ResetPassword {...props} />} />
            <Route exact path="/register" render={props => <Register {...props} />} />
            <Route exact path="/subscription" component={props => <Subscribe {...props} />} />
            <Route exact path="/support" render={props => <Support {...props} />} />
            <Route exact path="/business" render={props => <Business {...props} />} />
            <Route exact path="/dns/approval" render={props => <Approval {...props} />} />
            <Route exact path="/create" render={props => <Create {...props} />} />
            <Route exact path="/auth/verify" render={props => <AuthVerify {...props} />} />
            <Route exact path="/sessionend" component={logout} />
          </Switch>
        </main>
      </div>
    );
  }
}

export default withRouter(App);
