import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Jumbotron from 'react-bootstrap/Jumbotron';

export const StepTwoName = inject("rootStore")(observer((props) => (
    <div hidden={props.step == 2 ? false : true}>
        <Jumbotron style={{ backgroundColor: 'rgb(244 248 250)' }}>
            <Row>
                <Col style={{ textAlign: 'center', marginTop: '30px' }}>
                    <h3>Step 2: Name your Bot</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="botUrl">
                        <Form.Label>Bot Name</Form.Label>
                        <Form.Control autocomplete="off" onChange={props.handleNameChange} value={props.botName} type="url" required />
                        <Form.Text className="text-muted">
                            Example: Work Clock In
                                        </Form.Text>
                    </Form.Group>
                    <Row>
                        <Col>
                            <div style={{ textAlign: 'left' }}>
                                <Button variant="primary" onClick={props.onClickStepBack} type="button"> Back</Button>
                            </div>
                        </Col>
                        <Col>
                            <div style={{ textAlign: 'right' }}>
                                <Button variant="primary" onClick={props.onSaveNameClick} type="button" disabled={props.disableStep2Button}> Next</Button>
                            </div>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Jumbotron>
    </div >
)));
