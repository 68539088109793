import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';
export const MiniBotSearch = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col>
                    <Form.Group >
                        <Typeahead
                            id="basic-typeahead-single"
                            labelKey="miniBotName"
                            onMenuToggle={props.questionObj.onMiniBotMenuToggle(props.questionObj.navigateQuestionnaire(props.questionObj.steps, props.questionObj.question.positiveQuestionType))}
                            onChange={props.questionObj.handleAnswer1TextChange(props.questionObj.questionPlacement, props.questionObj.question.positiveQuestionType)}
                            options={props.questionObj.miniBotSearchType == 0 ? props.questionObj.supportedGolfCourses :
                                props.questionObj.miniBotSearchType == 1 ? props.questionObj.supportedTennisCourts :
                                    props.questionObj.miniBotSearchType == 2 ? props.questionObj.supportedPickleBallCourts :
                                        props.questionObj.miniBotSearchType == 3 ? props.questionObj.supportedCampingSites : 
                                        props.questionObj.miniBotSearchType == 4 ? props.questionObj.supportedDinnerSites :[]}
                            placeholder={props.questionObj.miniBotSearchType == 0 ? "Enter Golf Course" :
                                props.questionObj.miniBotSearchType == 1 ? "Enter Tennis Court" :
                                    props.questionObj.miniBotSearchType == 2 ? "Enter Pickleball Court" :
                                        props.questionObj.miniBotSearchType == 3 ? "Enter Camping Site" :
                                        props.questionObj.miniBotSearchType == 4 ? "Enter Reservation Site": "Enter Name"}
                            emptyLabel="Can't find? Click Not Listed."
                        />

                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="btn btn-primary" disabled={props.questionObj.isAnswerButtonDisabled} onClick={props.questionObj.navigateQuestionnaire(props.questionObj.steps, props.questionObj.question.positiveQuestionType)} block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div>
    )
}));