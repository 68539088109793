import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import Lottie from 'react-lottie';
import Modal from 'react-bootstrap/Modal'
import * as animationAlmostFinished from '../../assets/Almost finished_animation.json';
import * as animationBotFinished from '../../assets/Finished_animation.json';
import acceptIcon from '../../assets/Accept_icon.png';

function AnimationFinish(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body>
                <Row >
                    <Col>
                        <Lottie options={{
                            loop: true,
                            autoplay: true,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            },
                            animationData: props.isBotComplete ? animationBotFinished : animationAlmostFinished
                        }}
                            height={400}
                        />
                    </Col>
                </Row>
                <Row style={{ paddingTop: '20px' }}>
                    <Col style={{ textAlign: 'center' }} >
                        <h4 style={{ fontWeight: 'bold', display: 'inline', paddingRight: '8px' }}>{props.isBotComplete ? 'You Did It!' : 'Almost Finished'}</h4>
                        <img src={acceptIcon} height={24} width={24} style={{ marginBottom: '8px' }} />
                    </Col>

                </Row>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <span>{props.isBotComplete ? 'Your Bot is now setup and is scheduled to run.' : 'Thanks for using Bot-It. Your Bot is almost ready to run 💪 So what happens next? Over the next few hours, our team will verify, configure and finalize your Bot. We will email you when this is process is complete.'}</span>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <Button onClick={()=> {window.location = '/'}} variant="primary" style={{ backgroundColor: 'rgb(223 226 226', color: 'black' }}>Got It</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
export const Finish = inject("rootStore")(observer((props) => (
    props.showAnimation &&
    <div hidden={props.step == 4 ? false : true}>
        <AnimationFinish
            show={props.finishCreateBotModalShow}
            onHide={() => props.handleFinishModalShow(false)}
            isBotComplete={props.isBotComplete} />
    </div >
)));
