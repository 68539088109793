import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import bwLogo from '../../assets/Logo_BnW@3x.png';
import ApiClient from '../../util/ApiClient';
import { Button } from 'react-bootstrap';
@inject('rootStore')
@observer
export default class BusinessView extends Component {
    constructor() {
        super();
        this.state = {
            token: '',
            monthlyRunCount: '',
            botRunsAllowed: '',
            billingType: '',
            tokenCopyButtonText: 'Copy Token'
        };
    }
    componentDidMount() {
        ApiClient.get('user/elite/profile').then((response) => {
            if (response) {
                this.setState({ token: response.data.token, monthlyRunCount: response.data.monthlyRunCount, botRunsAllowed: response.data.botRunsAllowed, billingType: response.data.billingType })
            }
        }).catch((error) => {
        });
    }
    ezCopy = (e) => {
        const el = document.createElement('textarea');
        el.value = this.state.token;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.setState({ tokenCopyButtonText: 'Copied!' }, () => {
            setTimeout(() => { this.setState({ tokenCopyButtonText: 'Copy Token' }); }, 2000);
        })
    }
    render() {

        const rootStore = this.props.rootStore;

        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={12}>
                        <Jumbotron style={{ backgroundColor: '#ece6e6' }}>
                            <Row>
                                <Col style={{ textAlign: "center", marginBottom: "20px" }}>
                                    <img src={bwLogo} width="180px" height="90px" style={{ paddingRight: '10px' }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "center" }}>
                                    <h3>My Business Elite Settings</h3>
                                </Col>
                            </Row>
                            <Row hidden={this.state.billingType && this.state.billingType != "ByRuns"}>
                                <Col>
                                    <Form>
                                        <Form.Group controlId="name">
                                            <Form.Label>Bot Run Count: {this.state.monthlyRunCount} / {this.state.botRunsAllowed}</Form.Label>

                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form>
                                        <Form.Group controlId="name">
                                            <Form.Label>API Token</Form.Label>
                                            <Form.Control as="textarea" value={this.state.token} placeholder="API Token" disabled={true} rows="3" minLength={6} />
                                        </Form.Group>
                                        <Button onClick={this.ezCopy}>{this.state.tokenCopyButtonText}</Button>
                                    </Form>
                                </Col>
                            </Row>

                        </Jumbotron>

                    </Col>
                </Row>

            </Container >
        )
    }
}