import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
export const GoHome = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col>
                    <Button className="btn btn-primary" onClick={props.questionObj.navigateQuestionnaire(props.questionObj.steps, props.questionObj.question.positiveQuestionType)} block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div>
    )
}));