import React, { Component } from 'react';
import { Formik } from "formik";
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import bwLogo from '../../assets/Logo_BnW@3x.png';
import fbLogo from '../../assets/group3@3x.png';
import gLogo from '../../assets/googleLogo@3x.png';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import ApiClient from '../../util/ApiClient';
import Modal from 'react-bootstrap/Modal'
import { getParameterByName } from '../../util/queryString';

function ForgotPasswordModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <span>Please enter your email address to reset your password.</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="forgotEmail">
                            <Form.Control onChange={props.onForgotPasswordChange} value={props.forgotPasswordValue} type="email" placeholder="Email Address" required />
                        </Form.Group>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={props.disableForgotPasswordButton} onClick={props.onForgotPasswordClick}>
                    {!props.submitting ? "Send" : "Please wait..."}
                </Button>
                <span style={props.forgotPasswordLabelColorStyle} hidden={props.hideForgotPasswordLabel}>{props.forgotPasswordLabelValue}</span>
            </Modal.Footer>
        </Modal>
    );
}
@inject('rootStore')
@observer
export default class Login extends Component {
    constructor() {
        super();
        this.state = {
            apiError: '',
            forgotPasswordModalShow: false,
            forgotPasswordValue: '',
            disableForgotPasswordButton: true,
            disableGoogleLogin: false,
            disableFacebookLogin: false,
            hideForgotPasswordLabel: true,
            forgotPasswordLabelValue: '',
            forgotPasswordLabelColorStyle: {},
            submitting: false,
            deepSubscription: '',
            shareCode: null,
            referralCode: null
        };
    }

    componentDidMount() {
        const rootStore = this.props.rootStore;
        var sub = getParameterByName('sub', window.location.href);
        if (sub) {
            this.setState({ deepSubscription: (sub) });
        }
        var botCode = getParameterByName('c', window.location.href);
        if (botCode)
            this.setState({ shareCode: botCode })
        var refCode = getParameterByName('r', window.location.href);
        if (refCode)
            this.setState({ referralCode: refCode })
    }
    responseFacebook = (response) => {
        if (response && response.accessToken && response.name && response.age_range && response.age_range.min) {
            if (response.age_range.min < 18) { this.setState({ apiError: 'You must be of age 18+ to register' }); return; }
            ApiClient.postUrlEncoded('social',
                { firstName: response.name.split(" ")[0], lastName: response.name.split(" ")[1], email: response.email, token: response.accessToken, signupType: 'Facebook', shareCode: this.state.shareCode, referralCode: this.state.referralCode }).then((response) => {
                    if (response.status == 200) {
                        this.setState({ disableFacebookLogin: false, });
                        localStorage.setItem('token', response.data.access_token);
                        ApiClient.get('user/profile').then((response) => {
                            if (response) {
                                if (response.data.subscriptionState == "Inactive" || response.data.subscriptionState == "PaymentFailed") {
                                    window.location = this.state.deepSubscription && this.state.deepSubscription.length > 0 ? '/subscription?sub=' + this.state.deepSubscription : '/subscription';
                                } else {
                                    window.location = '/'
                                }
                            } else {
                                window.location = '/'
                            }
                        }).catch((error) => {
                            window.location = '/'
                        });
                    }
                    else {
                        this.setState({ disableFacebookLogin: false, apiError: 'Facebook login error' })
                    }
                }).catch((error) => {
                    this.setState({ disableFacebookLogin: false, apiError: error.response.data })
                });
        }
        else {
            this.setState({ disableFacebookLogin: false, apiError: 'Facebook login error' })
        }

    }
    responseGoogle = (response) => {
        if (response && response.tokenObj) {
            ApiClient.getGoogleUserData(response.accessToken).then((gResponse) => {
                if (gResponse && gResponse.data.ageRanges) {
                    var primary = gResponse.data.ageRanges.find(x => x.metadata.primary === true);
                    if (!primary) { this.setState({ apiError: "Error validating your age. Please try again" }); return; }
                    if (primary.ageRange === "AGE_RANGE_UNSPECIFIED" || primary.ageRange === "LESS_THAN_EIGHTEEN") { this.setState({ apiError: "You must be of age 18+ to register" }); return; }
                    ApiClient.postUrlEncoded('social',
                        { firstName: response.profileObj.givenName, lastName: response.profileObj.familyName, email: response.profileObj.email, token: response.tokenObj.id_token, signupType: 'Google', shareCode: this.state.shareCode, referralCode: this.state.referralCode }).then((response) => {
                            if (response.status == 200) {
                                this.setState({ disableGoogleLogin: false, })
                                localStorage.setItem('token', response.data.access_token);
                                ApiClient.get('user/profile').then((response) => {
                                    if (response) {
                                        if (response.data.subscriptionState == "Inactive" || response.data.subscriptionState == "PaymentFailed") {
                                            window.location = this.state.deepSubscription && this.state.deepSubscription.length > 0 ? '/subscription?sub=' + this.state.deepSubscription : '/subscription';
                                        } else {
                                            window.location = '/'
                                        }
                                    } else {
                                        window.location = '/'
                                    }
                                }).catch((error) => {
                                    window.location = '/'
                                });
                            }
                            else {
                                this.setState({ disableGoogleLogin: false, apiError: 'Google login error' })
                            }
                        }).catch((error) => {
                            this.setState({ disableGoogleLogin: false, apiError: error.response.data.error })
                        });
                }
                else {
                    this.setState({ disableGoogleLogin: false, apiError: "Error validating your age. Please try again" })
                }

            }).catch((error) => {
                this.setState({ disableGoogleLogin: false, apiError: "Error validating your age. Please try again" })
            });
        }
        else {
            this.setState({ disableGoogleLogin: false, apiError: 'Google login error' })
        }
    }
    responseErrorGoogle = (error) => {
        this.setState({ disableGoogleLogin: false, apiError: error.error })
    }
    signUpClick = () => {
        var finalRegUrl = '/register';
        if (this.state.deepSubscription && this.state.deepSubscription.length > 0) {
            finalRegUrl = '/register?sub=' + this.state.deepSubscription;
            if (this.state.shareCode && this.state.referralCode) {
                finalRegUrl += '&c=' + this.state.shareCode + "&r=" + this.state.referralCode;
            }
            else if (this.state.shareCode) {
                finalRegUrl += '&c=' + this.state.shareCode;
            }
            else if (this.state.referralCode) {
                finalRegUrl += '&r=' + this.state.referralCode;
            }
            window.location = finalRegUrl;
            return;
        } else {
            if (this.state.shareCode && this.state.referralCode) {
                finalRegUrl = '/register?c=' + this.state.shareCode + "&r=" + this.state.referralCode;
            }
            else if (this.state.shareCode) {
                finalRegUrl = '/register?c=' + this.state.shareCode;
            }
            else if (this.state.referralCode) {
                finalRegUrl = '/register?r=' + this.state.referralCode;
            }
        }
        window.location = finalRegUrl;
    }
    onClickForgotPassword = () => {
        this.onShowForgotPasswordModal(true)
    }
    onShowForgotPasswordModal = (show) => {
        this.setState({ forgotPasswordModalShow: show })
    }
    onForgotPasswordChange = (e) => {
        this.setState({ forgotPasswordValue: e.target.value }, () => {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.forgotPasswordValue)) {
                this.setState({ disableForgotPasswordButton: false })
            }
            else {
                this.setState({ disableForgotPasswordButton: true })
            }
        })

    }
    onForgotPasswordClick = () => {
        this.setState({ submitting: true, disableForgotPasswordButton: true, hideForgotPasswordLabel: true }, () => {
            ApiClient.post('user/password/reset', { emailAddress: this.state.forgotPasswordValue }).then((response) => {
                this.setState({
                    forgotPasswordLabelValue: 'Please check your email', forgotPasswordLabelColorStyle: { color: 'green' },
                    hideForgotPasswordLabel: false, submitting: false
                })
            }).catch((error) => {

                this.setState({
                    forgotPasswordLabelValue: error.response.data, forgotPasswordLabelColorStyle: { color: 'red' }, hideForgotPasswordLabel: false,
                    submitting: false
                })
            });
        })
    }
    render() {

        const rootStore = this.props.rootStore;

        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={12}>
                        <Jumbotron style={{ backgroundColor: '#ece6e6' }}>
                            <Row>
                                <Col style={{ textAlign: "center", marginBottom: "20px" }}>
                                    <img src={bwLogo} width="180px" height="90px" style={{ paddingRight: '10px' }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "center" }}>
                                    <h3>Welcome Back!</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row style={{ margin: "20px" }}>
                                        <Col>
                                            <FacebookLogin
                                                appId="372076303678139"
                                                autoLoad={false}
                                                callback={this.responseFacebook}
                                                fields="name,email,age_range"
                                                scope="email,user_age_range"
                                                render={renderProps => (

                                                    <Button disabled={this.state.disableFacebookLogin} style={{ backgroundColor: 'rgb(58 85 159)' }} size="lg" onClick={renderProps.onClick} block> <img src={fbLogo} width="15px" height="30px" style={{ float: 'left' }} />Log in with Facebook</Button>
                                                )}
                                                onClick={() => { this.setState({ disableFacebookLogin: true, }); }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ margin: "20px" }}>
                                        <Col>
                                            <GoogleLogin
                                                clientId="968432706105-ck3js1henp03nndkl4nd3ldbj00crvlc.apps.googleusercontent.com"
                                                autoLoad={false}
                                                render={renderProps => (
                                                    <Button disabled={this.state.disableGoogleLogin} style={{ backgroundColor: 'rgb(243 74 56)' }} size="lg" onClick={renderProps.onClick} block><img src={gLogo} width="30px" height="30px" style={{ float: 'left' }} />Log in with Google</Button>
                                                )}
                                                buttonText="Login"
                                                scope="https://www.googleapis.com/auth/profile.agerange.read"
                                                onSuccess={this.responseGoogle}
                                                onFailure={this.responseErrorGoogle}
                                                onRequest={() => { this.setState({ disableGoogleLogin: true, }); }}
                                                cookiePolicy={'single_host_origin'}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ margin: "20px" }}>
                                        <Col>
                                            <Formik
                                                initialValues={{
                                                    userEmail: "",
                                                    userPassword: "",
                                                }}
                                                onSubmit={(values, actions) => {
                                                    ApiClient.postUrlEncoded('login', {
                                                        email: values.userEmail,
                                                        password: values.userPassword,
                                                        shareCode: this.state.shareCode
                                                    }).then((response) => {
                                                        if (response.status == 200) {
                                                            localStorage.setItem('token', response.data.access_token);
                                                            ApiClient.get('user/profile').then((response) => {
                                                                if (response) {
                                                                    if (response.data.subscriptionState == "Inactive" || response.data.subscriptionState == "PaymentFailed") {
                                                                        window.location = this.state.deepSubscription && this.state.deepSubscription.length > 0 ? '/subscription?sub=' + this.state.deepSubscription : '/subscription';
                                                                    } else {
                                                                        window.location = '/'
                                                                    }
                                                                } else {
                                                                    window.location = '/'
                                                                }
                                                            }).catch((error) => {
                                                                window.location = '/'
                                                            });
                                                        }
                                                        else {
                                                            actions.setSubmitting(false);
                                                            values.userPassword = '';
                                                            actions.setValues(values, false);
                                                            this.setState({ apiError: "Login Error. Please try again" })
                                                        }

                                                    }).catch((error) => {
                                                        actions.setSubmitting(false);
                                                        values.userPassword = '';
                                                        actions.setValues(values, false)
                                                        this.setState({ apiError: error.response.data })

                                                    })


                                                }}
                                            >
                                                {formik => (
                                                    <div>
                                                        <Form onSubmit={formik.handleSubmit}>
                                                            <Form.Group controlId="userEmail">
                                                                <Form.Label>Email address</Form.Label>
                                                                <Form.Control onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.userEmail} type="email" placeholder="Enter email" required />
                                                            </Form.Group>

                                                            <Form.Group controlId="userPassword">
                                                                <Form.Label>Password</Form.Label>
                                                                <Form.Control onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.userPassword} type="password" placeholder="Password" required />
                                                            </Form.Group>
                                                            <Row style={{ textAlign: 'center' }}>
                                                                <Col lg={12}>
                                                                    <Button variant="primary" type="submit" disabled={formik.isSubmitting}> {formik.isSubmitting ? "Please wait..." : "Submit"}</Button>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ textAlign: 'center' }}>
                                                                <Col lg={12}>
                                                                    <span style={{ color: 'red', margin: '20px' }}>{this.state.apiError}</span>
                                                                </Col>
                                                            </Row>

                                                        </Form>

                                                    </div>


                                                )}
                                            </Formik>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "center" }}>
                                    <span>Don't have account?  <Button style={{ verticalAlign: 'baseline' }} onClick={this.signUpClick} variant="link">Register with Email</Button></span>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "center" }}>
                                    <Button style={{ verticalAlign: 'baseline' }} onClick={this.onClickForgotPassword} variant="link">Forgot Password</Button>
                                </Col>
                            </Row>
                        </Jumbotron>

                    </Col>

                </Row>
                <ForgotPasswordModal
                    show={this.state.forgotPasswordModalShow}
                    onHide={() => this.onShowForgotPasswordModal(false)}
                    onForgotPasswordChange={this.onForgotPasswordChange}
                    forgotPasswordValue={this.state.forgotPasswordValue}
                    disableForgotPasswordButton={this.state.disableForgotPasswordButton}
                    onForgotPasswordClick={this.onForgotPasswordClick}
                    forgotPasswordLabelColorStyle={this.state.forgotPasswordLabelColorStyle}
                    hideForgotPasswordLabel={this.state.hideForgotPasswordLabel}
                    forgotPasswordLabelValue={this.state.forgotPasswordLabelValue}
                />
            </Container >
        )
    }
}
