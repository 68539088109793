import React, { Component } from 'react';
import { Formik } from "formik";
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import forgalLogo from '../../assets/FGG phthalo@4x.png';
import ApiClient from '../../util/ApiClient';
import { getParameterByName } from '../../util/queryString';
import fbLogo from '../../assets/group3@3x.png';
import gLogo from '../../assets/googleLogo@3x.png';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import TiktokPixel from 'tiktok-pixel';

TiktokPixel.init('CG99DTBC77UCLSGQSU3G');

@inject('rootStore')
@observer
export default class Register extends Component {
    constructor() {
        super();
        this.state = {
            apiError: '',
            deepSubscription: '',
            disableGoogleLogin: false,
            disableFacebookLogin: false,
            shareCode: null,
            referralCode: null
        };
    }
    componentDidMount() {
        const rootStore = this.props.rootStore;
        var sub = getParameterByName('sub', window.location.href);
        if (sub) {
            this.setState({ deepSubscription: (sub) });
        }
        var botCode = getParameterByName('c', window.location.href);
        if (botCode)
            this.setState({ shareCode: botCode })
        var refCode = getParameterByName('r', window.location.href);
        if (refCode)
            this.setState({ referralCode: refCode })
    }
    handlePostAuthentication = (destination) => {
        var finalDist = destination;
        if (this.state.deepSubscription && this.state.deepSubscription.length > 0) {
            finalDist = destination + '&sub=' + this.state.deepSubscription;
            if (this.state.shareCode && this.state.referralCode) {
                finalDist += '&c=' + this.state.shareCode + "&r=" + this.state.referralCode;
            }
            else if (this.state.shareCode) {
                finalDist += '&c=' + this.state.shareCode;
            }
            else if (this.state.referralCode) {
                finalDist += '&r=' + this.state.referralCode;
            }
            window.location = finalDist;
            return;
        } else {
            if (this.state.shareCode && this.state.referralCode) {
                finalDist = destination + 'c=' + this.state.shareCode + "&r=" + this.state.referralCode;
            }
            else if (this.state.shareCode) {
                finalDist = destination + 'c=' + this.state.shareCode;
            }
            else if (this.state.referralCode) {
                finalDist = destination + '&r=' + this.state.referralCode;
            }
        }
        window.location = finalDist;
    }
    loginClick = () => {
        this.handlePostAuthentication('/login?');
    }
    responseFacebook = (response) => {
        if (response && response.accessToken && response.name && response.age_range && response.age_range.min) {
            if (response.age_range.min < 18) { this.setState({ apiError: 'You must be of age 18+ to register' }); return; }
            ApiClient.postUrlEncoded('social',
                { firstName: response.name.split(" ")[0], lastName: response.name.split(" ")[1], email: response.email, token: response.accessToken, signupType: 'Facebook', shareCode: this.state.shareCode, referralCode: this.state.referralCode }).then((response) => {
                    if (response.status == 200) {
                        this.setState({ disableFacebookLogin: false, });
                        localStorage.setItem('token', response.data.access_token);
                        ApiClient.get('user/profile').then((response) => {
                            if (response) {
                                TiktokPixel.track('CompleteRegistration');
                                if (response.data.subscriptionState == "Inactive" || response.data.subscriptionState == "PaymentFailed") {
                                    this.handlePostAuthentication('/subscription?sub=sp');
                                } else {
                                    this.handlePostAuthentication('/subscription?sub=sp');
                                }
                            } else {
                                this.handlePostAuthentication('/subscription?sub=sp');
                            }
                        }).catch((error) => {
                            window.location = '/'
                        });
                    }
                    else {
                        this.setState({ disableFacebookLogin: false, apiError: 'Facebook login error' })
                    }
                }).catch((error) => {
                    this.setState({ disableFacebookLogin: false, apiError: error.response.data })
                });
        }
        else {
            this.setState({ disableFacebookLogin: false, apiError: 'Facebook login error' })
        }

    }
    responseGoogle = (response) => {
        if (response && response.tokenObj) {
            ApiClient.getGoogleUserData(response.accessToken).then((gResponse) => {
                if (gResponse && gResponse.data.ageRanges) {
                    var primary = gResponse.data.ageRanges.find(x => x.metadata.primary === true);
                    if (!primary) { this.setState({ apiError: "Error validating your age. Please try again" }); return; }
                    if (primary.ageRange === "AGE_RANGE_UNSPECIFIED" || primary.ageRange === "LESS_THAN_EIGHTEEN") { this.setState({ apiError: "You must be of age 18+ to register" }); return; }
                    ApiClient.postUrlEncoded('social',
                        { firstName: response.profileObj.givenName, lastName: response.profileObj.familyName, email: response.profileObj.email, token: response.tokenObj.id_token, signupType: 'Google', shareCode: this.state.shareCode, referralCode: this.state.referralCode }).then((response) => {
                            if (response.status == 200) {
                                this.setState({ disableGoogleLogin: false, })
                                localStorage.setItem('token', response.data.access_token);
                                ApiClient.get('user/profile').then((response) => {
                                    if (response) {
                                        TiktokPixel.track('CompleteRegistration');
                                        if (response.data.subscriptionState == "Inactive" || response.data.subscriptionState == "PaymentFailed") {
                                            this.handlePostAuthentication('/subscription?sub=sp');
                                        } else {
                                            this.handlePostAuthentication('/subscription?sub=sp');
                                        }
                                    } else {
                                        this.handlePostAuthentication('/subscription?sub=sp');
                                    }
                                }).catch((error) => {
                                    window.location = '/'
                                });
                            }
                            else {
                                this.setState({ disableGoogleLogin: false, apiError: 'Google login error' })
                            }
                        }).catch((error) => {
                            this.setState({ disableGoogleLogin: false, apiError: error.response.data.error })
                        });
                }
                else {
                    this.setState({ disableGoogleLogin: false, apiError: "Error validating your age. Please try again" })
                }

            }).catch((error) => {
                this.setState({ disableGoogleLogin: false, apiError: "Error validating your age. Please try again" })
            });
        }
        else {
            this.setState({ disableGoogleLogin: false, apiError: 'Google login error' })
        }
    }
    responseErrorGoogle = (error) => {
        this.setState({ disableGoogleLogin: false, apiError: error.error })
    }
    render() {

        const rootStore = this.props.rootStore;

        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={12}>
                        <Jumbotron style={{ backgroundColor: 'rgb(255 215 0)' }}>
                            <Row>
                                <Col style={{ textAlign: "center", marginBottom: "20px" }}>
                                    <img src={forgalLogo} width="190px" height="60px" style={{ paddingRight: '10px' }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "center" }}>
                                    <h3>Create Account</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: "center" }}>
                                    <span>Already have an account?<Button style={{ verticalAlign: 'baseline' }} onClick={this.loginClick} variant="link">Login Here</Button></span>

                                </Col>
                            </Row>
                            <Row style={{ margin: "20px" }}>
                                <Col>
                                    <FacebookLogin
                                        appId="372076303678139"
                                        autoLoad={false}
                                        callback={this.responseFacebook}
                                        fields="name,email,age_range"
                                        scope="email,user_age_range"
                                        render={renderProps => (

                                            <Button disabled={this.state.disableFacebookLogin} style={{ backgroundColor: 'rgb(58 85 159)' }} size="lg" onClick={renderProps.onClick} block> <img src={fbLogo} width="15px" height="30px" style={{ float: 'left' }} />Register with Facebook</Button>
                                        )}
                                        onClick={() => { this.setState({ disableFacebookLogin: true, }); }}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ margin: "20px" }}>
                                <Col>
                                    <GoogleLogin
                                        clientId="968432706105-ck3js1henp03nndkl4nd3ldbj00crvlc.apps.googleusercontent.com"
                                        autoLoad={false}
                                        render={renderProps => (
                                            <Button disabled={this.state.disableGoogleLogin} style={{ backgroundColor: 'rgb(243 74 56)' }} size="lg" onClick={renderProps.onClick} block><img src={gLogo} width="30px" height="30px" style={{ float: 'left' }} />Register with Google</Button>
                                        )}
                                        buttonText="Login"
                                        scope="https://www.googleapis.com/auth/profile.agerange.read"
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseErrorGoogle}
                                        onRequest={() => { this.setState({ disableGoogleLogin: true, }); }}
                                        cookiePolicy={'single_host_origin'}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Formik
                                        initialValues={{
                                            firstName: "",
                                            lastName: "",
                                            userEmail: "",
                                            userPassword: "",
                                            userBirthDate: ""
                                        }}
                                        onSubmit={(values, actions) => {
                                            ApiClient.postUrlEncoded('register', {
                                                firstName: values.firstName,
                                                lastName: values.lastName,
                                                email: values.userEmail,
                                                password: values.userPassword,
                                                birthdate: values.userBirthDate,
                                                signupType: 'Email',
                                                shareCode: this.state.shareCode,
                                                referralCode: this.state.referralCode
                                            }).then((response) => {
                                                if (response.status == 200) {
                                                    localStorage.setItem('token', response.data.access_token);
                                                    TiktokPixel.track('CompleteRegistration');
                                                    this.handlePostAuthentication('/subscription?sub=sp');
                                                }
                                                else {
                                                    actions.setSubmitting(false);
                                                    actions.setValues(values, false);
                                                    this.setState({ apiError: "Login Error. Please try again" })
                                                }

                                            }).catch((error) => {
                                                actions.setSubmitting(false);
                                                actions.setValues(values, false)
                                                this.setState({ apiError: error.response.data.error })

                                            })


                                        }}
                                    >
                                        {formik => (
                                            <Form onSubmit={formik.handleSubmit}>
                                                <Form.Group controlId="firstName">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.firstName} placeholder="First Name" required />
                                                </Form.Group>
                                                <Form.Group controlId="lastName">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.lastName} placeholder="Last Name" required />
                                                </Form.Group>
                                                <Form.Group controlId="userEmail">
                                                    <Form.Label>Email address</Form.Label>
                                                    <Form.Control type="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.userEmail} placeholder="Enter email" required />
                                                </Form.Group>

                                                <Form.Group controlId="userPassword">
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control type="password" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.userPassword} placeholder="Password" minLength={6} required />
                                                </Form.Group>
                                                <Form.Group controlId="userBirthDate">
                                                    <Form.Label>Birthday</Form.Label>
                                                    <Form.Control type="date" min="1900-01-01" max="2005-01-01" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.userBirthDate} placeholder="MM / DD / YYYY" required />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="terms">
                                                        <a href='https://docs.google.com/document/d/e/2PACX-1vQzvtasrhKix6gL9aTBY-0N0IRSsLSWhOjGpBuRrzRpmllZYJrSIilOHAw-7TkUV5kYPf4QpLMn6aDh/pub' target='_blank'>
                                                        <Form.Check type="checkbox" label="" style={{display:'inline'}} required />
                                                        Agree to Terms of Service
                                                        </a>
                                                </Form.Group>
                                                <Row style={{ textAlign: 'center' }}>
                                                    <Col lg={12}>
                                                        <Button variant="primary" type="submit" disabled={formik.isSubmitting}> {formik.isSubmitting ? "Please wait..." : "Register"}</Button>
                                                    </Col>
                                                </Row>
                                                <Row style={{ textAlign: 'center' }}>
                                                    <Col lg={12}>
                                                        <span style={{ color: 'red', margin: '20px' }}>{this.state.apiError}</span>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </Col>
                            </Row>

                        </Jumbotron>

                    </Col>
                </Row>

            </Container>
        )
    }
}