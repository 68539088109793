import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
export const TimezonePicker = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Time Zones:</Form.Label>
                        <Form.Control onChange={props.questionObj.handleOnTimeZoneChange(props.questionObj.questionPlacement)} as="select">
                            <option>--</option>
                            {props.questionObj.supportedTimeZones.map((item) => (
                                <option selected={props.questionObj.answer1 == item.id}>{item.id}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="btn btn-primary" disabled={props.questionObj.isAnswerButtonDisabled} onClick={props.questionObj.navigateQuestionnaire(props.questionObj.steps, props.questionObj.question.positiveQuestionType)} block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div >
    )
}));