import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
export const SpreadsheetUrl = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Control placeholder="https://www.zoho.com/sheet/MyDataImportSheetLink" autocomplete="off" onChange={props.questionObj.handleAnswer1TextChange(props.questionObj.questionPlacement, props.questionObj.question.positiveQuestionType)} value={props.questionObj.answer1} type="text" required />
                        <Form.Text className="text-muted">Public Zoho Spreadsheet Link</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="btn btn-primary" disabled={props.questionObj.isAnswerButtonDisabled} onClick={props.questionObj.navigateQuestionnaire(props.questionObj.steps, props.questionObj.question.positiveQuestionType)} block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div>
    )
}));