import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import { WithOutContext as ReactTags } from 'react-tag-input-no-dnd';
const KeyCodes = {
    comma: 188,
    enter: 13
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const MiniBotTimeRangePicker = inject("rootStore")(observer((props) => {
    return (
        <div>
            <Row className="d-flex justify-content-center miniBotTagger">
                <ReactTags
                    classNames={{
                        tagInputField: 'form-control test'
                    }}
                    tags={props.questionObj.miniBotTags}
                    delimiters={delimiters}
                    handleDelete={props.questionObj.removeMiniBotTag}
                    handleAddition={props.questionObj.addMiniBotTag}
                    allowDeleteFromEmptyInput={false}
                    inputProps={{
                        autocomplete: "off"
                    }}
                    inputFieldPosition="top"
                    placeholder="Enter a new time then press enter..."
                    style={{ marginBottom: '30px' }}
                    ref={props.questionObj.setTextInputRef}
                />
            </Row>
            <Row>
                <Col>
                    <Button className="btn btn-primary" disabled={props.questionObj.miniBotTags && !props.questionObj.miniBotTags.length} onClick={props.questionObj.navigateQuestionnaire(props.questionObj.steps)} block>{props.questionObj.buttonText}</Button>
                </Col>
            </Row>
        </div>
    )
}));