import React, { Component } from 'react';
import { Formik } from "formik";
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import bwLogo from '../../assets/Logo_BnW@3x.png';
import ApiClient from '../../util/ApiClient';
import { getParameterByName } from '../../util/queryString';
import Modal from 'react-bootstrap/Modal'
import Yup from 'yup';
import Form from 'react-bootstrap/Form'
import Jumbotron from 'react-bootstrap/Jumbotron';
function ResetPasswordModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Password Reset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        {props.passwordResetText}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onclickGoToLogin} disabled={!props.resetSuccess}>
                    Login
                </Button>

            </Modal.Footer>
        </Modal>
    );
}
@inject('rootStore')
@observer
export default class ResetPassword extends Component {
    constructor() {
        super();
        this.state = {
            confirmedCode: false,
            open: false,
            passwordResetText: '',
            resetSuccess: false,
            resetPasswordModalShow: false
        };
    }

    componentDidMount() {
        const rootStore = this.props.rootStore;
        var token = getParameterByName('token', window.location.href);
        ApiClient.get('user/validate/reset/password/' + token).then((response) => {
            if (response.data.valid) {
                this.setState({ confirmedCode: true })
            }
            else {
                this.setState({ apiError: 'Invalid token. Please try again' })
            }
        }).catch((error) => { this.setState({ apiError: 'Invalid token. Please try again' }) });
    }
    onclickGoToLogin = () => {
        window.location = '/login'
    }

    onShowResetPasswordModal = (show) => {
        this.setState({ resetPasswordModalShow: show })
    }
    render() {

        const rootStore = this.props.rootStore;

        return (
            <Container>
                <Jumbotron style={{ backgroundColor: 'rgb(255 215 0)' }}>
                    <Row className="justify-content-md-center">
                        <Col style={{ marginBottom: "20px" }}>
                            <h1>Password Reset</h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col style={{ textAlign: "center", marginBottom: "20px" }}>
                            <img src={bwLogo} width="180px" height="90px" style={{ paddingRight: '10px' }} />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col lg={12}>
                            <Formik
                                initialValues={{
                                    userEmail: "",
                                    confirmPassword: "",
                                }}
                                validationSchema={Yup.object().shape({
                                    password: Yup.string()
                                        .min(6)
                                        .max(128)
                                        .required('Enter a mew password'),
                                    confirmPassword: Yup.string()
                                        .oneOf([Yup.ref('password'), null], "Both passwords must be the same")
                                        .required('Confirm password')

                                })}
                                onSubmit={(values, actions) => {
                                    this.setState({ confirmedCode: false })
                                    var token = getParameterByName('token', window.location.href);
                                    ApiClient.post('user/reset/password', { newPassword: values.confirmPassword, token: token })
                                        .then((response) => {
                                            if (response.data.updated) {
                                                this.setState({ confirmedCode: false, resetSuccess: true, resetPasswordModalShow: true, passwordResetText: 'Your Password has been reset!' })
                                            }
                                        }).catch((error) => { this.setState({ resetPasswordModalShow: true, passwordResetText: error }) });

                                }}
                                render={({
                                    values,
                                    errors,
                                    touched,
                                    isValid,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group controlId="password">
                                                <Form.Label>New Password</Form.Label>
                                                <Form.Control disabled={!this.state.confirmedCode}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.password}
                                                    error={errors.password}
                                                    isInvalid={!!errors.password}
                                                    type="password"
                                                    placeholder="New Password"
                                                    required />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.password}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="confirmPassword">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control onChange={handleChange}
                                                    disabled={!this.state.confirmedCode}
                                                    onBlur={handleBlur}
                                                    value={values.confirmPassword}
                                                    error={errors.confirmPassword}
                                                    isInvalid={!!errors.confirmPassword}
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    required />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.confirmPassword}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Row style={{ textAlign: 'center' }}>
                                                <Col lg={12}>
                                                    <Button variant="primary" type="submit" disabled={isSubmitting || !this.state.confirmedCode || !!errors.password || !!errors.confirmPassword}> {isSubmitting ? "Please wait..." : "Submit"}</Button>
                                                </Col>
                                            </Row>
                                            <Row style={{ textAlign: 'center' }}>
                                                <Col lg={12}>
                                                    <span style={{ color: 'red', margin: '20px' }}>{this.state.apiError}
                                                        {this.state.apiError && <a href="/login"><br />Go Back</a>}</span>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                            />
                        </Col>
                    </Row>
                    <ResetPasswordModal
                        show={this.state.resetPasswordModalShow}
                        onHide={() => this.onShowResetPasswordModal(false)}
                        passwordResetText={this.state.passwordResetText}
                        resetSuccess={this.state.resetSuccess}
                        onclickGoToLogin={this.onclickGoToLogin}

                    />
                </Jumbotron>
            </Container >
        )
    }
}
